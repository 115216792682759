<template>
  <div>
    <animation-slide>
      <ul :class="$style.list">
        <slot></slot>
      </ul>
    </animation-slide>
    <app-button
      v-if="expandable"
      button-type="basic"
      :icon="buttonIcon"
      :class="$style['btn-toggle']"
      @click="toggleMore"
    >
      {{ buttonText }}
    </app-button>
  </div>
</template>

<script>
import AnimationSlide from '@pon/pu-animation-slide';
import AppButton from '@pon/pu-atom-app-button';

export default {
  components: {
    AnimationSlide,
    AppButton,
  },
  props: {
    expandable: {
      type: Boolean,
      default: false,
    },
    expanded: {
      type: Boolean,
      default: false,
    },
  },
  inject: {
    translations: {
      default: {
        showMoreDealers: 'showMoreDealers',
        showLessDealers: 'showLessDealers',
      },
    },
  },
  computed: {
    buttonText() {
      const { expanded, translations } = this;
      return expanded ? translations.showLessDealers : translations.showMoreDealers;
    },
    buttonIcon() {
      const { expanded } = this;
      return expanded ? 'chevron-up' : 'chevron-down';
    },
  },
  methods: {
    toggleMore() {
      this.$emit('toggle-expanded');
    },
  },
};
</script>

<style module lang="scss">
.list {
  @include list-reset;
}
.btn-toggle svg {
  transform: translate(-0.5em, -0.1em);
}
</style>
