import helpers from './helpers';

const operators = {
  isEqualTo: (fieldValue, conditionValue) => {
    let result = false;
    const fVal = helpers.normalize(fieldValue);
    const cVal = helpers.normalize(conditionValue);
    if (fVal === cVal) {
      result = true;
    }
    if (cVal.length) {
      const left = helpers.toNumber(cVal);
      if (typeof left === 'number') {
        const right = helpers.toNumber(fVal);
        result = typeof right === 'number' && left === right;
      }
    }
    return result;
  },
  isNotEqualTo: (fieldValue, conditionValue) => {
    let result = true;
    const fVal = helpers.normalize(fieldValue);
    const cVal = helpers.normalize(conditionValue);
    if (fVal === cVal) {
      result = false;
    }
    if (cVal.length) {
      const left = helpers.toNumber(cVal);
      if (typeof left === 'number') {
        const right = helpers.toNumber(fVal);
        result = typeof right !== 'number' || left !== right;
      }
    }
    return result;
  },
  contains: (fieldValue, conditionValue) => {
    return helpers.indexOf(fieldValue, conditionValue) >= 0;
  },
  doesNotContain: (fieldValue, conditionValue) => {
    return helpers.indexOf(fieldValue, conditionValue) === -1;
  },
  startsWith: (fieldValue, conditionValue) => {
    return helpers.indexOf(fieldValue, conditionValue) === 0;
  },
  doesNotStartWith: (fieldValue, conditionValue) => {
    return helpers.indexOf(fieldValue, conditionValue) !== 0;
  },
  endsWith: (fieldValue, conditionValue) => {
    return helpers.endsWith(fieldValue, conditionValue);
  },
  doesNotEndWith: (fieldValue, conditionValue) => {
    return !helpers.endsWith(fieldValue, conditionValue);
  },
  isGreaterThan: (fieldValue, conditionValue) => {
    const fVal = helpers.normalize(fieldValue);
    const cVal = helpers.normalize(conditionValue);
    let result = fVal > cVal;
    if (cVal.length) {
      const left = helpers.toNumber(cVal);
      if (typeof left === 'number') {
        const right = helpers.toNumber(fVal);
        result = typeof right === 'number' && right > left;
      }
    }
    return result;
  },
  isGreaterThanOrEqualTo: (fieldValue, conditionValue) => {
    const fVal = helpers.normalize(fieldValue);
    const cVal = helpers.normalize(conditionValue);
    let result = fVal >= cVal;
    if (fVal === cVal) {
      result = true;
    }
    if (cVal.length) {
      const left = helpers.toNumber(cVal);
      if (typeof left === 'number') {
        const right = helpers.toNumber(fVal);
        result = typeof right === 'number' && right >= left;
      }
    }
    return result;
  },
  isLessThan: (fieldValue, conditionValue) => {
    const fVal = helpers.normalize(fieldValue);
    const cVal = helpers.normalize(conditionValue);
    let result = fVal < cVal;
    if (cVal.length) {
      const left = helpers.toNumber(cVal);
      if (typeof left === 'number') {
        const right = helpers.toNumber(fVal);
        result = typeof right === 'number' && right < left;
      }
    }
    return result;
  },
  isLessThanOrEqualTo: (fieldValue, conditionValue) => {
    const fVal = helpers.normalize(fieldValue);
    const cVal = helpers.normalize(conditionValue);
    let result = fVal <= cVal;
    if (fVal === cVal) {
      result = true;
    }
    if (cVal.length) {
      const left = helpers.toNumber(cVal);
      if (typeof left === 'number') {
        const right = helpers.toNumber(fVal);
        result = typeof right === 'number' && right <= left;
      }
    }
    return result;
  },
};

const operatorTypes = new Map();
operatorTypes.set('{1D38B217-A2EE-4E7B-B6ED-13E751462FEB}', operators.isEqualTo);
operatorTypes.set('{49F47E77-E8C5-46F9-BF39-78D6B0D40B48}', operators.isNotEqualTo);
operatorTypes.set('{BF8935A6-1976-43A0-ABA5-D0BC128A76EA}', operators.contains);
operatorTypes.set('{45AAB0FB-775B-40F5-B3B8-7CAE3ABBF643}', operators.doesNotContain);
operatorTypes.set('{FD10F291-3C2E-4AE7-8A67-2F8271CB3DF2}', operators.startsWith);
operatorTypes.set('{6B92597D-F2E0-47D3-A40D-59AFB37EEDE5}', operators.doesNotStartWith);
operatorTypes.set('{D375ED5B-E156-4A2B-9F91-DFD5B03F0D78}', operators.endsWith);
operatorTypes.set('{F3AC7A1A-3458-4385-BB65-860315313DB3}', operators.doesNotEndWith);
operatorTypes.set('{61FF63A0-375C-47BD-9986-1F81BD12BBBB}', operators.isGreaterThan);
operatorTypes.set('{062C6ED9-EA6E-4A88-AE54-C88E2147971D}', operators.isGreaterThanOrEqualTo);
operatorTypes.set('{8FE41E53-AD87-4D24-B50F-EA0F6BDF739F}', operators.isLessThan);
operatorTypes.set('{88AC1C6B-BAFE-40A7-BB75-E304C8EC29DD}', operators.isLessThanOrEqualTo);

const operatorFactory = (operatorId) => {
  return operatorTypes.get(operatorId);
};

export { operatorFactory, operators };
