// import OneColumnGrid from './components/field-templates/OneColumnGrid.vue';
// import TwoColumnGridLeft from './components/field-templates/TwoColumnGridLeft.vue';
// import TwoColumnGridRight from './components/field-templates/TwoColumnGridRight.vue';
import Section from './components/fields/FormSection.vue';
import TextField from './components/fields/TextField.vue';
import SubmitButton from './components/fields/SubmitButton.vue';
import SingleLineText from './components/fields/SingleLineText.vue';
import MultiLineText from './components/fields/MultiLineText.vue';
import EmailField from './components/fields/EmailField.vue';
import TelephoneField from './components/fields/TelephoneField.vue';
import CheckboxField from './components/fields/CheckboxField.vue';
import CheckboxList from './components/fields/CheckboxList.vue';
import RadioButtonList from './components/fields/RadioButtonList.vue';
import DropdownList from './components/fields/DropdownList.vue';
import DateField from './components/fields/DateField.vue';
import ListBox from './components/fields/ListBox.vue';
import NumberField from './components/fields/NumberField.vue';
import PasswordField from './components/fields/PasswordField.vue';
import PasswordConfirmation from './components/fields/PasswordConfirmation.vue';

const fieldComponents = new Map();
// fieldComponents.set('{DF7CD782-0156-4DA5-9AC5-7B809FC23DE1}', OneColumnGrid);
// fieldComponents.set('{1A59C79E-5AC4-4DD4-9F1E-9C3FBF78C89C}', TwoColumnGridLeft);
// fieldComponents.set('{B2F9E928-EC93-410B-BE9A-A09D06AD89ED}', TwoColumnGridRight);
fieldComponents.set('{447AA745-6D29-4B65-A5A3-8173AA8AF548}', Section);
fieldComponents.set('{983BFA5F-C6B6-4AEE-A3BB-46B95D52E7DF}', TextField);
fieldComponents.set('{7CE25CAB-EF3A-4F73-AB13-D33BDC1E4EE2}', SubmitButton);
fieldComponents.set('{4EE89EA7-CEFE-4C8E-8532-467EF64591FC}', SingleLineText);
fieldComponents.set('{A296A1C1-0DA0-4493-A92E-B8191F43AEC6}', MultiLineText);
fieldComponents.set('{04C39CAC-8976-4910-BE0D-879ED3368429}', EmailField);
fieldComponents.set('{DF74F55B-47E6-4D1C-92F8-B0D46A7B2704}', TelephoneField);
fieldComponents.set('{4DA85E8A-3B48-4BC6-9565-8C1F5F36DD1B}', CheckboxField);
fieldComponents.set('{D86A361A-D4FF-46B2-9E97-A37FC5B1FE1A}', CheckboxList);
fieldComponents.set('{EDBD38A8-1AE9-42EC-8CCD-F5B0E2998B4F}', RadioButtonList);
fieldComponents.set('{E0CFADEE-1AC0-471D-A820-2E70D1547B4B}', DropdownList);
fieldComponents.set('{38137D30-7B2A-47D5-BBD8-133252C01B28}', DateField);
fieldComponents.set('{222A2121-D370-4C6F-80A3-03C930B718BF}', ListBox);
fieldComponents.set('{5B153FC0-FC3F-474F-8CB8-233FB1BEF292}', NumberField);
fieldComponents.set('{668A1C37-9D6B-483B-B7C1-340C92D04BA4}', PasswordField);
fieldComponents.set('{6293530F-36A1-4CA6-A2E6-C59C9343F096}', PasswordConfirmation);

export default function fieldComponentFactory(fieldTypeItemId) {
  return fieldComponents.get(fieldTypeItemId);
}
