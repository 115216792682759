<template>
  <animation-slide>
    <component :is="field.model.htmlTag" :class="field.model.cssClass" v-show="isVisible">
      <span v-html="`${field.model.text}`"></span>
    </component>
  </animation-slide>
</template>

<script>
import BaseField from './BaseField.vue';

export default {
  name: 'TextField',
  extends: BaseField,
};
</script>
