<template>
  <div :class="$style['tooltip']">
    <button ref="trigger" @click="openTooltip" @keydown.esc="closeTooltip" type="button">
      <slot name="trigger" />
    </button>

    <div>
      <animation-slide>
        <div
          v-if="showTooltip"
          ref="tooltipContent"
          :class="[$style.content, $style[contentDirection]]"
        >
          <slot />
        </div>
      </animation-slide>
    </div>
  </div>
</template>

<script>
import AnimationSlide from '@pon/pu-animation-slide';

export default {
  components: {
    AnimationSlide,
  },
  props: {
    contentDirection: {
      type: String,
      default: 'bottom-left',
      validator: (value) =>
        ['bottom-left', 'bottom-right', 'top-left', 'top-right'].indexOf(value) !== -1,
    },
  },
  data() {
    return {
      showTooltip: false,
    };
  },
  mounted() {
    window.addEventListener('resize', this.positionTooltip);
    document.body.addEventListener('click', this.closeTooltip);
  },
  unmounted() {
    window.removeEventListener('resize', this.positionTooltip);
    document.body.removeEventListener('click', this.closeTooltip);
  },
  methods: {
    openTooltip() {
      this.showTooltip = false;
      setTimeout(() => {
        this.showTooltip = true;
        this.$nextTick(() => this.positionTooltip());
      }, 100);
    },
    positionTooltip() {
      if (!this.$refs.tooltipContent) {
        return;
      }

      this.resetTooltipStyling();
      const triggerEl = this.$refs.trigger.getBoundingClientRect();
      const tooltipContentEl = this.$refs.tooltipContent.getBoundingClientRect();
      const directionY = this.contentDirection.split('-')[0];

      if (
        (directionY === 'top' && triggerEl.top - tooltipContentEl.height > 0) ||
        (directionY === 'bottom' && triggerEl.bottom + tooltipContentEl.height > window.outerHeight)
      ) {
        this.$refs.tooltipContent.style.bottom = `${tooltipContentEl.top - triggerEl.top}px`;
      }

      if (tooltipContentEl.left < 0) {
        this.$refs.tooltipContent.style.right = `${tooltipContentEl.left}px`;
      }

      if (window.outerWidth < tooltipContentEl.right) {
        this.$refs.tooltipContent.style.left = `${window.outerWidth - tooltipContentEl.right}px`;
      }
    },
    resetTooltipStyling() {
      this.$refs.tooltipContent.style.left = '';
      this.$refs.tooltipContent.style.right = '';
    },
    closeTooltip() {
      this.showTooltip = false;
    },
  },
};
</script>

<style module lang="scss">
.tooltip {
  position: relative;
  display: inline-block;
}

.content {
  min-width: 12em;
  z-index: z-index(tooltip);
  position: absolute;
  padding: 0.5rem 1rem;
  background-color: $gray-lighter;
}

.top-left,
.bottom-left {
  right: 0;
}

.top-right,
.bottom-right {
  left: 0;
}
</style>
