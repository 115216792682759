import axios from 'axios';
import emitter from 'mitt';

const EventBus = emitter;
const HTTPClient = axios.create({
  baseURL: `${process.env.VUE_APP_APIM_HOST}`,
  headers: {
    'Content-Type': 'application/json',
    'Ocp-Apim-Subscription-Key': process.env.VUE_APP_APIM_SUBSCRIPTIONKEY,
  },
});

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    // it isn't immediately obvious when you log error, but you can get more detailed data from error.config, error.request and error.response
    EventBus.emit('axios-interceptor-throws-error', error);
  }
);

export default HTTPClient;
