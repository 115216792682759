import { operatorFactory } from './components/conditions/operators';
import actionTypeFactory from './components/conditions/actions';
import matchTypeFactory from './components/conditions/matchTypes';

const Conditions = {
  operatorFactory,
  actionTypeFactory,
  matchTypeFactory,
};

export default Conditions;
