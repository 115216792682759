<template>
  <div>
    <div :class="$style['search-wrapper']">
      <div :class="$style['input-wrapper']">
        <form-input
          :class="$style.field"
          :id="id"
          :placeholder="placeholder"
          :name="name"
          :title="title"
          :disabled="disabled"
          :required="required"
          v-model:value="userInput"
          ref="search"
          type="search"
          @keyup.enter.prevent="handleManualFormSubmit"
        />
        <button
          :disabled="!userInput.length"
          :class="$style['btn-submit']"
          @click.prevent="handleManualFormSubmit"
        >
          <span :class="$style['sr-only']">
            {{ translations.search }}
          </span>
          <app-icon :class="$style['icon-search']" icon="search" />
        </button>
      </div>

      <div :class="$style['buttons']">
        <app-button
          ref="btn-user-location"
          button-type="basic"
          icon="crosshair"
          :class="$style['button-location']"
          :disabled="disabled"
          @click="getUserGeoLocation"
        >
          {{ translations.yourLocation }}
        </app-button>
        <slot name="buttons"></slot>
      </div>
    </div>
    <app-loader v-if="loading" />
    <error-message :text="error" />
    <div v-show="false" ref="placesServiceResults" />
  </div>
</template>

<script>
import AppIcon from '@pon/pu-atom-app-icon';
import AppButton from '@pon/pu-atom-app-button';
import FormInput from '@pon/pu-molecule-form-input';
import ErrorMessage from '@pon/pu-atom-error-message';
import AppLoader from '@pon/pu-atom-app-loader';
import axios from 'axios';
import urlParams from '@/plugins/url-params';
import { fetchEstablishmentsById } from '@/api/dealers';
import { googleMapsApi } from './helpers/googleMapsApi';

export default {
  components: {
    ErrorMessage,
    AppIcon,
    AppButton,
    FormInput,
    AppLoader,
  },
  props: {
    required: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    id: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  inject: {
    translations: {
      default: {
        errorUnknowLocation: 'errorUnknowLocation',
        errorGeolocationTurnedOff: 'errorGeolocationTurnedOff',
      },
    },
  },
  data() {
    return {
      userInput: '',
      error: null,
      loading: false,
    };
  },
  async mounted() {
    googleMapsApi(process.env.VUE_APP_GOOGLE_MAPS_API_KEY).then(() => this.initPlacesService());
    this.preselectDealerFromUrl();
  },
  methods: {
    preselectDealerFromUrl() {
      const preselectedEstablishmentId = urlParams.get('DealerPreferenceSalesDealer');
      if (preselectedEstablishmentId) {
        this.loading = true;
        fetchEstablishmentsById(preselectedEstablishmentId)
          .then(({ establishments }) => {
            this.userInput = (establishments.length && establishments[0].postalCode) || '';
          })
          .catch((err) => {
            this.error = err;
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    getPostalCodeFromSearch(result) {
      /* eslint-disable camelcase */
      const postalCode = result?.address_components?.find(
        (address) => address.types[0] === 'postal_code'
      );
      return postalCode?.long_name;
      /* eslint-enable camelcase */
    },
    initPlacesService() {
      const { search } = this.$refs;
      const input = search.$el.querySelector('input');
      const placesAutocomplete = new window.google.maps.places.Autocomplete(input, {
        types: ['(regions)'],
        componentRestrictions: { country: 'nl' },
        fields: ['address_components', 'geometry'],
      });
      placesAutocomplete.addListener('place_changed', () => {
        const result = placesAutocomplete.getPlace();
        const latitude = result?.geometry?.location?.lat();
        const longitude = result?.geometry?.location?.lng();
        const postalCode = this.getPostalCodeFromSearch(result);
        if (postalCode) {
          this.submitSearch(postalCode);
        } else if (latitude && longitude) {
          this.searchPostalCodeByCoords(latitude, longitude);
        }
      });
    },
    getCaseInsensitivePropertyValue(object, propertyName) {
      if (!object || !propertyName) {
        return null;
      }
      let value;
      Object.keys(object).forEach((objectPropertyName) => {
        if (objectPropertyName.toLowerCase() === propertyName.toLowerCase()) {
          value = object[objectPropertyName];
        }
      });
      return value;
    },
    /**
     * Performs a custom address+geo lookup of user input when the Autocomplete is bypassed
     * by submitting the search form.
     */
    handleManualFormSubmit() {
      const autocompleteService = new window.google.maps.places.AutocompleteService();
      const placesService = new window.google.maps.places.PlacesService(
        this.$refs.placesServiceResults
      );
      if (!this.userInput) {
        return;
      }
      autocompleteService.getPlacePredictions(
        {
          types: ['(regions)'],
          componentRestrictions: { country: 'nl' },
          input: this.userInput,
        },
        (results, getPlacePredictionsStatus) => {
          if (results?.length && getPlacePredictionsStatus === 'OK') {
            // get the result from the list
            const selectedResult = results.find(
              (item) => item.description === this.$refs.search.value
            );
            // fill the searchbox with the selected value or the first result found
            if (selectedResult) {
              this.userInput = selectedResult.description;
            } else {
              this.userInput = results[0].description;
            }
            placesService.getDetails(
              {
                placeId: results[0].place_id,
              },
              (result, status) => {
                if (result && status === 'OK') {
                  const latitude = result.geometry?.location?.lat();
                  const longitude = result.geometry?.location?.lng();
                  this.searchPostalCodeByCoords(latitude, longitude);
                } else {
                  this.error = this.translateGoogleApiErrorResponse(status);
                }
              }
            );
          } else {
            this.error = this.translateGoogleApiErrorResponse(getPlacePredictionsStatus);
          }
        }
      );
    },
    async searchPostalCodeByCoords(latitude, longitude) {
      this.$emit('select-postal-code', null);
      this.error = null;
      this.loading = true;
      try {
        const response = await axios('https://maps.googleapis.com/maps/api/geocode/json', {
          params: {
            latlng: `${latitude},${longitude}`,
            key: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
            language: 'nl-NL',
            result_type: 'street_address|postal_code',
          },
        });
        const [firstResult] = response?.data?.results;
        if (!firstResult) throw response.data.error_message;
        const postalCode = this.getPostalCodeFromSearch(firstResult);
        // eslint-disable-next-line camelcase
        this.userInput = firstResult.formatted_address;
        this.submitSearch(postalCode);
      } catch (error) {
        this.error = error;
        this.submitSearch(null);
      } finally {
        this.loading = false;
      }
    },
    submitSearch(postalCode) {
      this.$emit('select-postal-code', postalCode);
    },
    getUserGeoLocation() {
      navigator.geolocation.getCurrentPosition(
        this.handleGeolocatorQuery,
        this.showGeoLocatorError
      );
    },
    async handleGeolocatorQuery(geoLocationPosition) {
      this.userInput = '';
      const { latitude, longitude } = geoLocationPosition.coords;
      this.searchPostalCodeByCoords(latitude, longitude);
    },
    showGeoLocatorError(error) {
      this.error = this.translateGoogleApiErrorResponse(error.message);
    },
    translateGoogleApiErrorResponse(response) {
      switch (response) {
        case 'ZERO_RESULTS':
          return this.translations.errorUnknowLocation;
        case 'User denied geolocation prompt':
        case 'User denied Geolocation':
          return this.translations.errorGeolocationTurnedOff;
        default:
          return response;
      }
    },
  },
};
</script>

<style module lang="scss">
$location-search-icon-bg-color: $location-search-icon-bg-color;
$location-search-icon-bg-color-hover: $location-search-icon-bg-color-hover;
$location-search-icon-color: inherit !default;
$location-search-icon-color-disabled: $gray-light !default;
$location-search-icon-color-hover: $location-search-icon-color !default;

@include sr-only;

.search-wrapper {
  display: grid;
  grid-gap: 1em;

  @include breakpoint(sm) {
    grid-gap: 0.5em;
    align-items: end;
    grid-template-columns: 1fr auto;
  }
}

.buttons {
  display: flex;
  flex-wrap: wrap;
  font-size: 0.75em;

  @include breakpoint(sm) {
    margin: 0 0 4px 0;
  }

  & > * {
    margin-bottom: 1em;

    @include breakpoint(sm) {
      margin-bottom: 0;
    }
  }

  & > * + * {
    margin-left: 1em;
  }
}

.button-location {
  margin-right: 1em !important;
  color: $button-location !important;
}

.input-wrapper {
  position: relative;

  & div {
    margin-bottom: 0;
  }
}

.field {
  input {
    padding-right: 3em;
  }
  margin-bottom: 0 !important;
}

.btn-submit {
  $margin: 2px;

  position: absolute;
  height: 2.25em;
  right: $margin;
  bottom: 2px;
  display: flex;
  align-items: center;
  background: $location-search-icon-bg-color;
  padding: 0 $spacing-xs;
  color: $location-search-icon-color !important;

  &:hover:not(:disabled) {
    background: $location-search-icon-bg-color-hover;
    color: $location-search-icon-color-hover;
  }

  &[disabled] {
    color: $location-search-icon-color-disabled;
  }
}

.icon-search {
  @include icon(1.25em);
}
</style>
