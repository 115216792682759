/**
 * There are no actual action function declarations here because the actions
 * are all methods on the formfields, not actual functions
 */
const actionTypes = new Map();
actionTypes.set('{AAE07A52-46A4-49EF-98B0-C2595BAC2382}', 'show');
actionTypes.set('{7F58C8DD-D7C0-4FB7-BB44-8EC6B5E1C3D9}', 'hide');
actionTypes.set('{5744A87E-E32C-42CC-862F-96842A0202BB}', 'enable');
actionTypes.set('{C698C993-549E-486A-A09C-BB8D830DA958}', 'disable');
actionTypes.set('{4E448D57-BA06-42DC-9519-6BCD102CB332}', 'gotopage');

const oppositeActions = {
  show: 'hide',
  enable: 'disable',
  gotopage: 'gotopage',
};

const actionTypeFactory = (actionTypeId, conditionResult) => {
  let actionName = actionTypes.get(actionTypeId).toLowerCase();

  if (!conditionResult) {
    if (Object.keys(oppositeActions).includes(actionName)) {
      actionName = oppositeActions[actionName];
    } else {
      Object.keys(oppositeActions).forEach((key) => {
        if (oppositeActions[key] === actionName) {
          actionName = key;
        }
      });
    }
  }
  return actionName;
};

export default actionTypeFactory;
