import modules from './modules';

export default function addEventToDigitalData(method, args) {
  if (typeof window === 'undefined') {
    return;
  }

  if (!modules[method]) {
    return;
  }

  const data = modules[method](args);

  const dataToAdd = {
    ...data,
  };

  if (dataToAdd.pageUrl?.includes('/#/') || dataToAdd.pageUrl?.includes('#/')) {
    dataToAdd.pageUrl = dataToAdd.pageUrl?.replace(/\/#\/|#\//g, '/');
  }

  // eslint-disable-next-line no-unused-expressions
  window.digitalData?.addEvent(dataToAdd);
}
