/*
SSR-safe Read-only access for URL variables

Usage:

import urlParams from '@/plugins/url-params';
...
urlParams.get('variableName')

*/
const paramsString =
  typeof window !== 'undefined' &&
  (window?.location?.search === '' ? window?.location?.href.split('?')[1] : window.location.search);

const envReady = typeof window !== 'undefined' && paramsString && window.URLSearchParams;

const params = envReady ? new URLSearchParams(paramsString) : undefined;

export default {
  // get given key. will default to undefined
  get(key) {
    return params ? params.get(key) : undefined;
  },
};
