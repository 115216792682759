<template>
  <animation-slide>
    <form-radio-and-checkbox-fieldset :title="title" v-show="isVisible" :required="required">
      <form-radiobutton
        v-for="item in field.model.items"
        :key="item.itemId"
        v-model:value="value"
        :input-value="item.value"
        :name="field.valueField.name"
        :id="item.itemId"
        :title="item.text"
        :disabled="!isEnabled"
        :class="field.model.cssClass"
        @change="onInput($event.target.value)"
        @focus="field.tracker.onFocusField(field, value)"
        @blur="field.tracker.onBlurField(field, value, errors.value)"
        :error-message="errors.value.join(',')"
      />
      <error-message :text="errors.value.join(',')" icon="error" />
    </form-radio-and-checkbox-fieldset>
  </animation-slide>
</template>

<script>
import FormRadioAndCheckboxFieldset from '@pon/atom-form-radio-and-checkbox-fieldset';
import FormRadiobutton from '@pon/atom-form-radio-button';
import ErrorMessage from '@pon/pu-atom-error-message';
import BaseField from './BaseField.vue';

export default {
  name: 'RadioButtonList',
  extends: BaseField,
  components: {
    FormRadioAndCheckboxFieldset,
    FormRadiobutton,
    ErrorMessage,
  },
  beforeMount() {
    if (Array.isArray(this.field.value)) {
      this.value = this.field.value.join('');
    }
  },
};
</script>
