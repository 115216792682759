<template>
  <animation-slide>
    <form-input
      v-show="isVisible"
      :id="field.valueField.id"
      v-model:value="value"
      type="date"
      :class="field.model.cssClass"
      :name="field.valueField.name"
      :title="title"
      :min="minDate"
      :max="maxDate"
      :placeholder="field.model.placeholderText"
      :required="required"
      :disabled="!isEnabled"
      @input="onInput($event.target.value)"
      @focus="field.tracker.onFocusField(field, value)"
      @blur="onBlurHandler(field, value, errors.value)"
      :error-message="errors.value.join(',')"
      icon="error"
    />
  </animation-slide>
</template>

<script>
import FormInput from '@pon/pu-molecule-form-input';
import { format } from 'date-fns';
import BaseField from './BaseField.vue';

export default {
  name: 'DateField',
  components: {
    FormInput,
  },
  extends: BaseField,
  data() {
    return {
      value: this.formatDate(this.field.value),
    };
  },
  computed: {
    minDate() {
      return this.formatDate(this.field.model.min);
    },
    maxDate() {
      return this.formatDate(this.field.model.max);
    },
  },
  methods: {
    formatDate(value) {
      if (!value) return null;
      return format(new Date(value), 'u-MM-dd');
    },
  },
};
</script>
