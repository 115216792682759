<template>
  <div v-show="visible" class="google-map"></div>
</template>

<script>
import { MarkerClusterer } from '@googlemaps/markerclusterer';
import addEventToDigitalData from '../../helpers/DigitalData';
import { googleMapsApi } from './helpers/googleMapsApi';

export default {
  props: {
    mapConfig: {
      type: Object,
      default: () => {},
    },
    establishments: {
      type: Array,
      default: () => [],
    },
    selectedId: {
      type: String,
      default: null,
    },
    visible: {
      type: Boolean,
      default: true,
    },
  },
  inject: {
    translations: {
      default: {
        selectThisDealer: 'selectThisDealer',
        selectedDealer: 'selectedDealer',
      },
    },
  },
  data() {
    return {
      google: null,
      map: null,
      establishmentMapObjects: {},
      openMarkerEstablishmentId: '',
      markerClusterer: null,
      boundsNL: null,
      renderer: null,
    };
  },
  watch: {
    establishments: {
      immediate: true,
      handler() {
        if (this.markerClusterer) {
          this.establishmentMapObjects = {};
          this.markerClusterer.clearMarkers();
          this.initializeMap();
        }
        this.setGoogle().then(this.createMapObjects);
      },
    },
    markers() {
      if (this.visible) {
        this.setMarkers();
      }
    },
    visible(mapIsVisible) {
      if (mapIsVisible) {
        this.initializeMap();
      }
    },
    openMarkerEstablishmentId(newId, oldId) {
      if (oldId && this.establishmentMapObjects[oldId]) {
        this.establishmentMapObjects[oldId].infoWindow.close();
      }
      this.establishmentMapObjects[newId].infoWindow.open(
        this.map,
        this.establishmentMapObjects[newId].marker
      );
    },
    selectedId(newId, oldId) {
      if (oldId) {
        this.updateInfoWidow(oldId);
      }
      this.updateInfoWidow(newId);
    },
  },
  beforeCreate() {
    //  kick off google maps api loading as soon as possible
    googleMapsApi(process.env.VUE_APP_GOOGLE_MAPS_API_KEY);
  },
  created() {
    this.setGoogle().then(() => {
      this.boundsNL = new this.google.maps.LatLngBounds(
        new this.google.maps.LatLng(50.75038379999999, 3.3316001), // sw
        new this.google.maps.LatLng(53.6316, 7.227510199999999) // ne
      );
    });
  },
  async mounted() {
    this.google = await googleMapsApi(process.env.VUE_APP_GOOGLE_MAPS_API_KEY);
    if (this.visible) {
      this.initializeMap();
    }
  },
  beforeUnmount() {
    this.$el.removeEventListener('click', this.mapContainerClickHandler);
  },
  computed: {
    establishmentIds() {
      return Object.keys(this.establishmentMapObjects);
    },
    markers() {
      const { establishmentIds } = this;
      return establishmentIds.map((id) => this.establishmentMapObjects[id].marker);
    },
    dealerColor() {
      switch (process.env.VUE_APP_BRAND) {
        case 'skoda':
          return '#4AA82E';
        case 'cupra':
          return '#003e51';
        case 'vwlkw':
        case 'vwpkw':
          return '#01B1EB';
        case 'audi':
        case 'grey-label':
        case 'seat':
        default:
          return '#d7122b';
      }
    },
    dataUriForMapMarker() {
      const svg = `
      <svg xmlns="http://www.w3.org/2000/svg" width="29" height="29" viewBox="0 0 7.673 7.673">
        <circle cx="75.943" cy="198.516" r="3.365" fill="#fff" stroke="${this.dealerColor}" stroke-width=".944" transform="translate(-72.107 -194.68)"/>
      </svg>`;
      return this.getDataUriFromSvg(svg);
    },
    dataUriForMapClusterMarker() {
      const svg = `
      <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 8.996 8.996">
        <rect width="8.043" height="8.043" x="128.454" y="145.947" ry="1.473" fill="#fff" stroke="${this.dealerColor}" stroke-width=".953" transform="translate(-127.978 -145.47)"/>
      </svg>`;
      return this.getDataUriFromSvg(svg);
    },
  },
  methods: {
    getDataUriFromSvg(svg) {
      return `data:image/svg+xml,${svg
        .replace(/"/g, "'")
        .replace(/>\s{1,}</g, `><`)
        .replace(/\s{2,}/g, ` `)
        .replace(/[\r\n%#()<>?[\\\]^`{|}]/g, encodeURIComponent)}`;
    },
    initializeMap() {
      if (!this.visible) {
        // no use if map is hidden
        return;
      }

      // set a click listener on the entire map container to catch clicks on JS-added elements
      this.$el.addEventListener('click', this.mapContainerClickHandler);

      // using a ref makes the Map instance lose its container upon hot reload
      // using this.$el bypasses this issue completely
      this.map = new this.google.maps.Map(this.$el);

      // since we cannot close clustered infoWindows, force close them when idle
      this.map.addListener('idle', () => {
        // defer the infoWIndow closing to prevent race conditions as cluster opens
        setTimeout(this.forceClosedWinfowindows, 0);
      });

      this.renderer = {
        render: ({ count, position }) => {
          return new this.google.maps.Marker({
            map: this.map,
            position,
            label: {
              text: String(count),
              color: 'black',
              fontSize: '10px',
              className: 'dealer-map-cluster',
            },
            zIndex: Number(this.google.maps.Marker.MAX_ZINDEX) + count,
            icon: {
              url: this.dataUriForMapClusterMarker,
              size: new this.google.maps.Size(34, 34),
            },
          });
        },
      };

      this.setMarkers();
    },
    setMarkers() {
      if (!this.visible) {
        // no use if map is hidden
        return;
      }

      const { boundsNL } = this;

      if (this.markers.length) {
        this.markerClusterer = new MarkerClusterer({
          map: this.map,
          markers: this.markers,
          renderer: this.renderer,
        });

        this.markerClusterer.addMarkers(this.markers);
        this.markerClusterer.render();
      } else {
        this.map.fitBounds(boundsNL);
      }
    },
    updateInfoWidow(establishmentId) {
      if (!this.establishmentIds.includes(establishmentId)) {
        return;
      }

      const { establishment } = this.establishmentMapObjects[establishmentId];
      const infoWindowContent = this.getInfoWidowContent(establishment);
      this.establishmentMapObjects[establishmentId].infoWindow.setContent(infoWindowContent);
    },
    getInfoWidowContent(establishment) {
      const isActiveEstablishment = establishment.id === this.selectedId;
      const check = isActiveEstablishment
        ? `<svg width="20" height="20" role="presentation"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#check-bold"></use></svg>`
        : '';
      const buttonText = isActiveEstablishment
        ? this.translations.selectedDealer
        : this.translations.selectThisDealer;
      const buttonClass = [
        'button-maps-info-window',
        isActiveEstablishment ? ' is-active' : '',
      ].join('');

      return `
        <h3>${establishment?.name}</h3>
        ${establishment?.addressLine ? `${establishment.addressLine}<br />` : ''}
        ${
          establishment?.postalCode && establishment?.city
            ? `${establishment?.postalCode} ${establishment?.city}<br />`
            : ''
        }
        <button
          class="${buttonClass}"
          data-establishment-id="${establishment.id}">
          ${check + buttonText}
        </button>`;
    },
    getEstablishmentMapObjects(establishment) {
      const { location } = establishment;
      let marker = null;
      let infoWindow = null;

      if (location.latitude && location.longitude) {
        marker = new this.google.maps.Marker({
          position: {
            lat: parseFloat(location.latitude),
            lng: parseFloat(location.longitude),
          },
          icon: this.dataUriForMapMarker,
        });

        if (this.map) {
          this.map.addListener('zoom_changed', () => {
            const zoom = this.map.getZoom();
            if (zoom <= 15) {
              marker.setMap(null);
            } else {
              marker.setMap(this.map);
            }
          });
        }

        infoWindow = new this.google.maps.InfoWindow({
          content: this.getInfoWidowContent(establishment),
        });

        marker.addListener('click', () => this.markerClickHandler(establishment));
      }

      return { marker, infoWindow };
    },
    mapContainerClickHandler(evt) {
      const { target } = evt;

      if (target.matches('[data-establishment-id]')) {
        const establishmentId = target.getAttribute('data-establishment-id');
        this.$emit('select-establishment', establishmentId);
      }
    },
    markerClickHandler(establishment) {
      this.openMarkerEstablishmentId = establishment.id;
      addEventToDigitalData('mapMarker', {
        pinName: establishment.dealerName,
        eventLabel: establishment.dealerName,
      });
    },
    async setGoogle() {
      if (!this.google) {
        this.google = await googleMapsApi(process.env.VUE_APP_GOOGLE_MAPS_API_KEY);
      }
      return this.google;
    },
    createMapObjects() {
      this.establishmentMapObjects = this.establishments.reduce(
        (acc, establishment) => ({
          ...acc,
          [establishment.id]: { ...this.getEstablishmentMapObjects(establishment), establishment },
        }),
        {}
      );
    },
    forceClosedWinfowindows() {
      const { establishmentIds, openMarkerEstablishmentId } = this;

      establishmentIds.forEach((establishmentId) => {
        if (openMarkerEstablishmentId !== establishmentId) {
          this.establishmentMapObjects[establishmentId].infoWindow.close();
        }
      });
    },
  },
};
</script>

<style lang="scss">
.google-map {
  width: 100%;
  min-height: 400px;
}

/* Min width of popup */
.gm-style-iw.gm-style-iw-c {
  min-width: 15rem;
}
</style>

<style lang="scss">
@import '@pon/pu-styles/lib/variables.scss';
@import '@pon/pu-styles/lib/mixins.scss';
@import '@pon/pu-atom-app-button/lib/scss/master.scss';

.button-maps-info-window {
  @include btn('primary');

  &.is-active {
    @include btn('ghost');
  }

  &,
  &.is-active {
    margin-top: 1em;

    svg {
      transform: translateY(0.15em);
      margin-right: 0.25em;
    }
  }
}

.dealer-map-cluster > div {
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}
</style>
