<template>
  <div>
    <visitor-identification />
    <div id="forms">
      <placeholder name="jss-main" :rendering="route" />
      <error-handler />
    </div>
  </div>
</template>

<script>
import { Placeholder } from '@sitecore-jss/sitecore-jss-vue';
import ErrorHandler from './components/Shared/ErrorHandler.vue';

import VisitorIdentification from './VisitorIdentification';
import '@/sass/defaults/app.scss';

export default {
  name: 'Layout',
  props: {
    route: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      title: null,
    };
  },
  /* eslint-disable consistent-return */
  metaInfo() {
    return {
      title: this.title,
    };
  },
  components: {
    ErrorHandler,
    Placeholder,
    VisitorIdentification,
  },
  created() {
    document.cookie = `lead-source-url=${window?.location?.href};domain=pon.cloud`;

    if (this.$jss.store.state.isFormStandalone && this.route.fields && !!this.route.fields['Meta Title']) {
      this.title = this.route?.fields['Meta Title'].value;
    }
  },
};
</script>
