<template>
  <animation-slide>
    <form-input
      v-model:value="value"
      v-show="isVisible"
      type="number"
      :id="field.valueField.id"
      :step="field.model.step"
      :min="field.model.min"
      :max="field.model.max"
      :placeholder="field.model.placeholderText"
      :name="field.model.name"
      :title="title"
      :required="required"
      :disabled="!isEnabled"
      @change="onInput(value)"
      @focus="field.tracker.onFocusField(field, value)"
      @blur="onBlurHandler(field, value, errors.value)"
      :error-message="errors.value.join(',')"
      icon="error"
      :class="field.model.cssClass"
    />
  </animation-slide>
</template>

<script>
import FormInput from '@pon/pu-molecule-form-input';
import BaseField from './BaseField.vue';

export default {
  extends: BaseField,
  components: {
    FormInput,
  },
};
</script>

<style>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}
</style>
