<template>
  <div>{{ field.model.title }} not implemented yet</div>
</template>

<script>
import BaseField from './BaseField.vue';

export default {
  props: {
    field: {
      type: Object,
      required: true,
    },
  },
  extends: BaseField,
};
</script>
