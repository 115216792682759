<template>
  <animation-slide>
    <form-fieldset :title="field.model.name" :class="field.model.cssClass">
      <component
        :is="component.component"
        v-for="component in field.fields"
        :key="component.props.key"
        :ref="component.props.ref"
        :field="component.props"
        @change="$emit('change')"
        :metadata="metadata"
      />
    </form-fieldset>
  </animation-slide>
</template>

<script>
import BaseField from './BaseField.vue';
import FormFieldset from '@pon/pu-atom-form-field-set';

export default {
  name: 'FormSection',
  extends: BaseField,
  components: {
    FormFieldset,
  },
  props: {
    metadata: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      fieldRefs: {},
    };
  },
  mounted() {
    this.fieldRefs = { ...this.fieldRefs, ...this.$parent.$refs, ...this.$refs };
    this.$emit('updateFieldRefs', this.fieldRefs);
  },
};
</script>
