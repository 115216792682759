<template>
  <fieldset :class="field.model.cssClass" v-show="isVisible">
    <component
      :is="component.component"
      v-for="component in field.fields"
      :key="component.props.key"
      :ref="component.props.ref"
      :field="component.props"
    />
  </fieldset>
</template>

<script>
import BaseField from './BaseField.vue';
export default {
  name: 'FormSection',
  extends: BaseField,
  data() {
    return {
      fieldRefs: {},
    };
  },
  mounted() {
    this.fieldRefs = { ...this.fieldRefs, ...this.$parent.$refs, ...this.$refs };
    this.$emit('updateFieldRefs', this.fieldRefs);
  },
};
</script>
