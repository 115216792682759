export default ({ selectedEstablishment }) => {
  return {
    tealium_event: 'add_my_dealer',
    dealerName: selectedEstablishment.dealerName,
    dealerID: selectedEstablishment.dealerId,
    dealerEstablishment: selectedEstablishment.name,
    dealerEstablishmentID: selectedEstablishment.id,
    eventCategory: 'add_my_dealer',
    eventAction: selectedEstablishment.dealerName,
    eventLabel: selectedEstablishment.name,
  };
};
