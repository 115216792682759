<template>
  <animation-slide>
    <form-input
      v-model:value="value"
      type="email"
      :id="field.valueField.id"
      :minlength="field.model.minLength"
      :maxlength="field.model.maxLength"
      :placeholder="field.model.placeholderText"
      :name="field.model.name"
      :title="title"
      :required="required"
      :disabled="!isEnabled"
      @change="onInput($event.target.value)"
      @focus="field.tracker.onFocusField(field, value)"
      @blur="onBlurHandler(field, value, errors.value)"
      v-show="isVisible"
      :error-message="errors.value.join(',')"
      icon="error"
      :class="field.model.cssClass"
    />
  </animation-slide>
</template>

<script>
import BaseField from './BaseField.vue';
import FormInput from '@pon/pu-molecule-form-input';

export default {
  components: {
    FormInput,
  },
  extends: BaseField,
};
</script>
