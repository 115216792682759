<script>
import { BaseField } from '../../Library/VueForm';
import addEventToDigitalData from '../../helpers/DigitalData';
import AnimationSlide from '@pon/pu-animation-slide';

export default {
  extends: BaseField,
  props: {
    metadata: {
      type: Object,
      required: true,
    },
  },
  components: {
    AnimationSlide,
  },
  computed: {
    title() {
      let { title } = this.field.model;
      if (this.required) {
        title = `${title}`;
      }
      return title;
    },
  },
  mounted() {
    const { queryStringParameterName } = this.field?.model;
    let params = window.location.search.substring(1).split('&');

    params = params.reduce((acc, item) => {
      if (!item.includes('=')) return acc;
      const [key, value] = item.split('=');
      return {
        ...acc,
        [key]: value,
      };
    }, {});

    if (
      window.location.search &&
      queryStringParameterName !== '' &&
      params[queryStringParameterName]
    ) {
      this.value = decodeURIComponent(params[queryStringParameterName]);
      this.field.model.value = this.value;
      this.onInput(this.value);
    }

    if (
      !window.location.search &&
      queryStringParameterName !== '' &&
      this.$route.query[queryStringParameterName]
    ) {
      this.value = this.$route.query[queryStringParameterName];
      this.onInput(this.value);
    }
  },
  methods: {
    onBlurHandler(field, value, error) {
      field.tracker.onBlurField(field, value, error);
      this.$emit('change');

      if (this.errors.value[0]) {
        addEventToDigitalData('formError', {
          eventAction: this.metadata.name,
          eventCategory: 'forms',
          eventLabel: 'form error',
          formName: this.metadata.name,
          formFieldError: this.field.model.name,
          formErrorMessage: this.errors.value[0],
        });
      }
    },
  },
};
</script>
