<template>
  <component :is="field.model.htmlTag" :class="field.model.cssClass" v-show="isVisible">
    {{ field.model.text }}
  </component>
</template>

<script>
import BaseField from './BaseField.vue';

export default {
  name: 'TextField',
  extends: BaseField,
};
</script>
