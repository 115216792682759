<template>
  <div class="invalid-feedback">
    <p v-for="error in errors" :key="error">
      {{ error }}
    </p>
  </div>
</template>

<script>
export default {
  props: {
    errors: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
