<template>
  <li>
    <bordered-container>
      <template v-slot:header>
        <div :class="$style.header">
          <div :class="$style.title">
            <h4>
              {{ establishment.name }}
            </h4>
            <span v-if="establishment.distanceInMeters" :class="$style.distance">
              {{ distanceInTime }} ({{ showDistanceInKm(establishment.distanceInMeters) }}
              {{ translations.km }})
            </span>
          </div>
          <div>
            <tooltip>
              <template v-slot:trigger>
                {{ translations.viewOpeningHours }}
                <app-icon :class="$style['icon-clock']" icon="clock" />
              </template>
              <opening-times
                :opening-times="establishment.openingHoursInformation.workshopOpeningHours"
              />
            </tooltip>
          </div>
        </div>
      </template>
      <div :class="$style.text">
        <div :class="$style.address">
          <address itemscope itemtype="http://schema.org/Organization">
            <div itemprop="address">
              <template v-if="establishment.addressLine">
                <span itemprop="streetAddress">
                  {{ establishment.addressLine }}
                </span>
                <br />
              </template>
              <template v-if="establishment.postalCode">
                <span itemprop="postal-code">
                  {{ establishment.postalCode }}
                </span>
              </template>
              <template v-if="establishment.city">
                <span itemprop="locality">{{ establishment.city }}</span>
                <br />
              </template>
              <a
                :href="`mailto:${emailAddress}`"
                itemprop="email"
                v-if="emailAddress"
                :class="$style.email"
              >
                {{ emailAddress }}
              </a>
            </div>
          </address>
        </div>
        <app-button
          :button-type="isSelectedDealer ? 'ghost' : 'primary'"
          @click="selectDealer"
          :icon-position-right="false"
          :class="$style['btn-select-dealer']"
          :icon="isSelectedDealer ? 'checkBold' : null"
        >
          {{ buttonText }}
        </app-button>
      </div>
    </bordered-container>
  </li>
</template>

<script>
import BorderedContainer from '@pon/pu-atom-bordered-container';
import AppButton from '@pon/pu-atom-app-button';
import AppIcon from '@pon/pu-atom-app-icon';
import OpeningTimes from './DealerSelectOpeningTimes';
import Tooltip from './Tooltip';

export default {
  name: 'DealerCard',
  components: {
    BorderedContainer,
    AppButton,
    OpeningTimes,
    Tooltip,
    AppIcon,
  },
  props: {
    establishment: {
      type: Object,
      required: true,
    },
    selectedId: {
      type: String,
      default: null,
    },
  },
  inject: {
    translations: {
      default: {
        km: 'km',
        viewOpeningHours: 'viewOpeningHours',
        selectedDealer: 'selectedDealer',
        selectThisDealer: 'selectThisDealer',
      },
    },
  },
  computed: {
    buttonText() {
      return this.isSelectedDealer
        ? this.translations.selectedDealer
        : this.translations.selectThisDealer;
    },

    isSelectedDealer() {
      return this.selectedId === this.establishment.id;
    },
    emailAddress() {
      return (
        this.establishment.showroomEmail || this.establishment.additionalContactInfo?.showroomEmail
      );
    },
    distanceInTime() {
      const hours = this.establishment.travelTime?.split(':')[0];
      const minutes = this.establishment.travelTime?.split(':')[1];

      if (hours) {
        return parseInt(hours, 10) === 0
          ? `${minutes}min.`
          : `${parseInt(hours, 10)}u ${minutes}min.`;
      }

      return '';
    },
  },
  methods: {
    selectDealer() {
      this.$emit('select-establishment', this.establishment.id);
    },
    showDistanceInKm(value) {
      const distanceInKm = value / 1000;
      return new Intl.NumberFormat('nl-NL', {
        maximumFractionDigits: 2,
      }).format(distanceInKm);
    },
  },
};
</script>

<style module lang="scss">
@include sr-only;

.icon-clock {
  transform: translateY(0.25em);
}

.email {
  word-break: break-all;
}

.title {
  display: flex;
  align-items: flex-start;
  margin: 0 0.5em 0.75em 0;
  flex: 1 1 auto;

  @include breakpoint(md) {
    margin-bottom: 0.25em;
  }
}

.header {
  @include breakpoint(md) {
    display: flex;
    align-items: flex-start;
  }
}

.distance {
  margin-left: 0.5em;
  color: $gray;
}

.text {
  @include breakpoint(md) {
    display: flex;
    align-items: flex-end;
  }
}

.address {
  flex: 1 1 auto;

  @include breakpoint(md) {
    padding-right: 0.5em;
  }
}

.btn-select-dealer {
  flex: 0 0 auto;
  margin: 1.5em 0 0 !important;

  @include breakpoint(md) {
    margin-top: 0;
  }

  svg {
    transform: translate(0.5em, -0.1em);
  }
}

.label {
  @include breakpoint(md) {
    display: flex;
    margin: 0 0 0 auto;
  }
}
</style>
