<template>
  <div class="form-group" v-show="isVisible">
    <form-label :for="field.valueField.id" :field="field" :is-valid="isValid" />
    <input
      :id="field.valueField.id"
      v-model="value"
      type="date"
      class="form-control"
      :class="[field.model.cssClass, { 'is-invalid': !isValid }]"
      :name="field.valueField.name"
      :min="field.model.min"
      :max="field.model.max"
      :placeholder="field.model.placeholderText"
      :required="required"
      :disabled="!isEnabled"
      @input="onInput($event.target.value)"
      @focus="field.tracker.onFocusField(field, value)"
      @blur="field.tracker.onBlurField(field, value, errors.value)"
    />
    <error-messages v-if="errors.value.length > 0" :errors="errors.value" />
  </div>
</template>

<script>
import BaseField from './BaseField.vue';
import FormLabel from './FormLabel.vue';

export default {
  name: 'DateField',
  components: {
    FormLabel,
  },
  extends: BaseField,
  data() {
    return {
      value: this.formatDate(this.field.value),
    };
  },
  created() {
    // sanitize attributes
    this.field.model.min = this.formatDate(this.field.model.min);
    this.field.model.max = this.formatDate(this.field.model.max);
  },
  methods: {
    formatDate(dateString) {
      return !dateString || dateString.length < 10 ? dateString : dateString.substring(0, 10);
    },
  },
};
</script>
