/**
 * Vuelidate Sitecore Number Step validator
 * @author Rocco Janse, roccojanse@outlook.com
 */
import { helpers } from '@vuelidate/validators';

export default (param) => {
  return helpers.withParams({ type: 'numberStep', step: param }, (value) => {
    return !helpers.req(value) || (value / param) % 1 === 0;
  });
};
