<template>
  <animation-slide>
    <div v-show="isVisible">
      <form-checkbox
        v-model:value="booleanFromStoreConvertedToArray"
        :input-value="field.model.itemId"
        :title="title"
        :id="field.valueField.id"
        :class="field.model.cssClass"
        :name="field.valueField.name"
        :required="required"
        :disabled="!isEnabled"
        @change="onInput(value)"
        @focus="field.tracker.onFocusField(field, value)"
        @blur="field.tracker.onBlurField(field, value, errors.value)"
      />
      <error-message
        v-show="errors.value.length"
        id="error-message-checkbox"
        icon="error"
        :text="errors.value.join(',')"
      />
    </div>
  </animation-slide>
</template>

<script>
import BaseField from './BaseField.vue';
import FormCheckbox from '@pon/atom-form-checkbox';
import ErrorMessage from '@pon/pu-atom-error-message';

export default {
  extends: BaseField,
  components: {
    FormCheckbox,
    ErrorMessage,
  },
  computed: {
    booleanFromStoreConvertedToArray: {
      get() {
        const isSelected = this.value;
        if (isSelected) {
          return [this.field.model.itemId];
        }
        return [];
      },
      set(value) {
        // value passed in is array that is either empty, or contains the itemId
        this.value = value.length > 0;
      },
    },
  },
  methods: {
    validate() {
      let isValid = true;

      // default validation
      if (this.v$.value) {
        this.v$.value.$touch();
        isValid = !this.v$.value.$error;
        this.getValidationErrors(this.v$.value);
      }

      // required field validation
      if (this.field.model.required && !this.value) {
        if (!this.errors.value.length) {
          // a specific error message is used for checkbox fields,
          // since the label could contain to much content to be used in nice error message.
          this.errors.value[0] = this.$t('forms-validations-required-checkbox');
        }
        isValid = false;
      }

      this.isValid = isValid;
      return this.isValid;
    },
  },
};
</script>
