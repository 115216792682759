import axios from '../plugins/axios-apim';

const brandCode = (() => {
  switch (process.env.VUE_APP_BRAND) {
    case 'audi':
      return 'A';
    case 'cupra':
      return 'U';
    case 'skoda':
      return 'C';
    case 'seat':
      return 'S';
    case 'vwpkw':
      return 'V';
    case 'vwlkw':
      return 'N';
    case 'porsche':
      return 'P';
    default:
      return 'brandcode-unknown';
  }
})();

let salesLicensesString;
async function getSalesLicenses() {
  if (!salesLicensesString) {
    try {
      const { data } = await axios.get(
        `${process.env.VUE_APP_APIM_DEALERS_PATH}/${brandCode}/licenses`
      );
      salesLicensesString = data.salesLicenses.join('&licenses=').replace(/ /g, '+');
    } catch (error) {
      console.error(error.message);
    }
  }

  return salesLicensesString;
}

const establishmentsFromResponse = (responseData) => {
  // single establishment
  if (responseData.establishment) {
    return [responseData.establishment];
  }

  // multiple establishments
  if (responseData.establishments) {
    // filter duplicates based on id
    const establishmentIds = [];
    return responseData.establishments.filter((es) => {
      if (establishmentIds.includes(es.id)) {
        return false;
      }

      establishmentIds.push(es.id);
      return true;
    });
  }

  // no establishments found
  return [];
};

export async function fetchEstablishmentsById(id) {
  const salesLicenses = await getSalesLicenses();

  let establishments = [];
  let error;

  if (!id)
    return {
      establishments,
      error: 'No id given',
    };

  const params = {
    brandCode,
  };

  const url = `${process.env.VUE_APP_APIM_DEALERS_PATH}/establishments/${id}?licenses=${salesLicenses}`;
  try {
    const { data } = await axios.get(url, { params });
    establishments = establishmentsFromResponse(data);
  } catch (err) {
    error = err;
  }

  return {
    establishments,
    error,
  };
}

export async function fetchEstablishmentsByPostalcode(postalCode) {
  const salesLicenses = await getSalesLicenses();

  let establishments = [];
  let error;

  if (!postalCode)
    return {
      establishments,
      error: 'No postalCode given',
    };

  const params = {
    brandCode,
    postalCode,
    licenseQueryLogic: 'Or',
  };

  const url = `${process.env.VUE_APP_APIM_DEALERS_PATH}/establishments?licenses=${salesLicenses}`;
  try {
    const { data } = await axios.get(url, { params });
    establishments = establishmentsFromResponse(data);
  } catch (err) {
    error = err;
  }

  return {
    establishments,
    error,
  };
}
