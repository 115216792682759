<template>
  <div>
    Placeholder for {{ componentName }}. This component {{ componentName }} cannot be used in
    combination with server side rendering
  </div>
</template>

<script>
export default {
  props: {
    componentName: {
      type: Number,
      required: true,
    },
  },
};
</script>
