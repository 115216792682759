export const QueryParameterNames = {
  INTERESTED_IN_VEHICLE_MODEL: 'InterestedInVehicleModel',
  MODEL_CODE: 'modelCode',
};

export const ErrorMessages = {
  NO_ID_GIVEN: 'No id given',
  NO_MATCHED_COMPONENTS: 'No matched components found by router for route ',
  NO_PROVIDER: 'No provider set up. Please run createGraphQLProvider first.',
  NO_MATCH_REPLACE_TOKEN: 'Unable to match replace token ',
  SERVER_SIDE_RENDERING_FAILED:
    ' in dist/index.html template. If the HTML shell for the app is modified, also fix the replaces in server.js. Server-side rendering has failed!',
};
