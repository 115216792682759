import {
  Button as ButtonConst,
  CheckboxField as CheckboxFieldConst,
  CheckboxList as CheckboxListConst,
  DateField as DateFieldConst,
  DropdownList as DropdownListConst,
  EmailField as EmailFieldConst,
  FormSection as FormSectionConst,
  ListBox as ListBoxConst,
  MultipleLineText as MultipleLineTextConst,
  NumberField as NumberFieldConst,
  PasswordConfirmField as PasswordConfirmFieldConst,
  PasswordField as PasswordFieldConst,
  RadioButtonList as RadioButtonListConst,
  RichText as RichTextConst,
  SingleLineText as SingleLineTextConst,
  AddressField as AddressFieldConst,
  TelephoneField as TelephoneFieldConst,
  HiddenField as HiddenFieldConst,
  DealerSelect as DealerSelectConst,
  ModelSelect as ModelSelectConst,
  Captcha as CaptchaConst,
} from '@/forms/fieldTypes';
import AppButton from '../components/Elements/AppButton.vue';
import CheckboxField from '../components/Elements/CheckboxField';
import CheckboxList from '../components/Elements/CheckboxList';
import DateField from '../components/Elements/DateField.vue';
import DefaultFieldComponent from '../components/Elements/DefaultFieldComponent';
import DropdownList from '../components/Elements/DropdownList.vue';
import EmailField from '../components/Elements/EmailField.vue';
import DealerSelect from '../components/Elements/DealerSelect.vue';
import FormSection from '../components/Elements/FormSection.vue';
import MultipleLineText from '../components/Elements/MultipleLineText.vue';
import NumberField from '../components/Elements/NumberField.vue';
import PasswordField from '../components/Elements/PasswordField.vue';
import RadioButtons from '../components/Elements/RadioButtons';
import RichText from '../components/Elements/RichText.vue';
import SingleLineText from '../components/Elements/SingleLineText.vue';
import AddressField from '../components/Elements/AddressField.vue';
import TelephoneField from '../components/Elements/TelephoneField.vue';
import HiddenField from '../components/Elements/HiddenField.vue';
import ModelSelect from '../components/Elements/ModelSelect.vue';
import Captcha from '../components/Elements/Captcha.vue';

const fieldComponents = new Map();
fieldComponents.set(ButtonConst, AppButton);
fieldComponents.set(CheckboxFieldConst, CheckboxField);
fieldComponents.set(CheckboxListConst, CheckboxList);
fieldComponents.set(DateFieldConst, DateField);
fieldComponents.set(DropdownListConst, DropdownList);
fieldComponents.set(EmailFieldConst, EmailField);
fieldComponents.set(DealerSelectConst, DealerSelect);
fieldComponents.set(ModelSelectConst, ModelSelect);
fieldComponents.set(CaptchaConst, Captcha);
fieldComponents.set(FormSectionConst, FormSection);
fieldComponents.set(ListBoxConst, DropdownList);
fieldComponents.set(MultipleLineTextConst, MultipleLineText);
fieldComponents.set(NumberFieldConst, NumberField);
// confirm password needs its own component; we can't simple re-use PasswordField
// since it should have two input fields
// assign to DefaultFieldComponent for now
fieldComponents.set(AddressFieldConst, AddressField);
fieldComponents.set(PasswordConfirmFieldConst, DefaultFieldComponent);
fieldComponents.set(PasswordFieldConst, PasswordField);
fieldComponents.set(RadioButtonListConst, RadioButtons);
fieldComponents.set(RichTextConst, RichText);
fieldComponents.set(SingleLineTextConst, SingleLineText);
fieldComponents.set(TelephoneFieldConst, TelephoneField);
fieldComponents.set(HiddenFieldConst, HiddenField);

/**
 *
 * @param {string} fieldTypeItemId - The sitecore fieldTypeItemId of a field
 * @returns {vueComponent} the vue component corresponding with the field
 */
export default (fieldTypeItemId) => {
  if (!fieldComponents.has(fieldTypeItemId)) {
    return DefaultFieldComponent;
  }

  return fieldComponents.get(fieldTypeItemId);
};
