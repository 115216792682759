<template>
  <div :class="$style['form-item']">
    <location-search
      :required="required"
      :name="name"
      :title="title"
      :id="id"
      :placeholder="placeholder"
      :disabled="disabled"
      @select-postal-code="selectPostalCode"
    >
      <template #buttons>
        <toggle-switch v-model:value="showMap" :label="translations.showMap" @input="toggleMap" />
      </template>
    </location-search>
    <app-loader v-if="loading" />
    <error-message :text="error" />
    <dealer-map
      :visible="showMap"
      :establishments="establishmentsForMap"
      :selected-id="establishmentSelectedId"
      :class="$style.map"
      @select-establishment="selectEstablishment"
    />
    <dealer-card-list
      v-if="!!establishmentsForList.length"
      :class="$style.dealers"
      :expandable="listIsExpandable"
      :expanded="listExpanded"
      @toggle-expanded="toggleListExpanded"
    >
      <dealer-card
        v-for="establishment in establishmentsForList"
        :key="`establishment-card-${establishment.id}`"
        :establishment="establishment"
        :selected-id="establishmentSelectedId"
        @select-establishment="selectEstablishment"
      />
    </dealer-card-list>
  </div>
</template>

<script>
import AppLoader from '@pon/pu-atom-app-loader';
import ErrorMessage from '@pon/pu-atom-error-message';
import ToggleSwitch from '../../components/Elements/ToggleSwitch.vue';
import addEventToDigitalData from '../../helpers/DigitalData';
import LocationSearch from './DealerSelectLocationSearch';
import DealerCardList from './DealerSelectCardList';
import DealerCard from './DealerSelectCard';
import DealerMap from './DealerSelectMap';

export default {
  name: 'DealerSelect',
  components: {
    DealerCard,
    DealerCardList,
    LocationSearch,
    DealerMap,
    ToggleSwitch,
    AppLoader,
    ErrorMessage
  },
  inject: {
    translations: {
      default: {
        showMap: 'showMap',
      },
    },
  },
  data() {
    return {
      listExpanded: false,
      showMap: false,
    };
  },
  computed: {
    establishmentsForList() {
      const { establishments, listExpanded, selectedEstablishment } = this;

      if (listExpanded) {
        return establishments; // show all
      }

      if (selectedEstablishment) {
        return [selectedEstablishment]; // show single
      }

      return establishments.slice(0, 3); // show first 3
    },
    establishmentsForMap() {
      // filter out any establishments with no geodata
      return this.establishments.filter(
        (es) => es.location && es.location.latitude && es.location.longitude
      );
    },
    listIsExpandable() {
      return this.establishments.length > 1;
    },
    selectedEstablishment() {
      const { establishments, establishmentSelectedId } = this;

      if (!establishmentSelectedId || !establishments.length) {
        return null;
      }

      return establishments.find((es) => es.id === establishmentSelectedId);
    },
  },
  props: {
    required: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    id: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    establishments: {
      type: Array,
      default: () => [],
    },
    establishmentSelectedId: {
      type: String,
      default: null,
    },
    error: {
      type: String,
      default: '',
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    selectEstablishment(establishmentId) {
      this.$emit('select-establishment', establishmentId);
    },
    selectPostalCode(postalCode) {
      this.$emit('postal-code-change', postalCode);
    },
    toggleListExpanded() {
      this.listExpanded = !this.listExpanded;
    },
    toggleMap(checkValue) {
      this.showMap = checkValue;
      const sliderAction = this.showMap ? 'on' : 'off';

      addEventToDigitalData('slider', {
        eventAction: 'dealer_search',
        sliderName: 'map visualization',
        sliderAction,
      });
    },
  },
  watch: {
    establishmentSelectedId(newValue) {
      if (newValue) {
        this.listExpanded = false;
      }
    },
  },
};
</script>

<style lang="scss" module>
.form-item {
  margin-bottom: 1em;
}
.dealers {
  margin-top: $spacing-s;
}
.map {
  margin-top: 2em;
}
</style>
