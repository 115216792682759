<template>
  <app-loader v-if="visible" />
</template>

<script>
import AppLoader from '@pon/pu-atom-app-loader';

export default {
  components: {
    AppLoader,
  },
  data() {
    return {
      visible: false,
    };
  },
  props: {
    delay: {
      type: Number,
      default: 300,
    },
  },
  created() {
    // Wait {this.delay}ms before making the loading graphic visible.
    // If whatever other async operation completes within that timeframe,
    // then whichever parent component invoked the loading component will
    // presumably re-render without the loading component.
    // This helps prevent the loading graphic from too briefly "flashing" on the screen
    // causing an undesirable flicker for users.
    setTimeout(() => {
      this.visible = true;
    }, this.delay);
  },
};
</script>
