<template>
  <animation-slide>
    <form-radio-and-checkbox-fieldset :title="title" v-show="isVisible" :required="required">
      <form-checkbox
        v-for="item in field.model.items"
        :key="item.itemId"
        v-model:value="value"
        :input-value="item.value"
        :name="field.valueField.name"
        :id="item.itemId"
        :title="item.text"
        :disabled="!isEnabled"
        @change="onInputChange"
        @focus="field.tracker.onFocusField(field, value)"
        @blur="field.tracker.onBlurField(field, value, errors.value)"
        :class="field.model.cssClass"
        :required="required"
        :checkbox-list="true"
      />
      <error-message :text="errors.value.join(',')" icon="error" />
    </form-radio-and-checkbox-fieldset>
  </animation-slide>
</template>

<script>
import FormRadioAndCheckboxFieldset from '@pon/atom-form-radio-and-checkbox-fieldset';
import FormCheckbox from '@pon/atom-form-checkbox';
import ErrorMessage from '@pon/pu-atom-error-message';
import BaseField from './BaseField.vue';
import addEventToDigitalData from '../../helpers/DigitalData';

export default {
  extends: BaseField,
  components: {
    FormRadioAndCheckboxFieldset,
    FormCheckbox,
    ErrorMessage,
  },
  props: {
    metadata: {
      type: Object,
      required: true,
    },
  },
  methods: {
    onInputChange() {
      this.onInput(this.value);

      if (this.errors.value[0]) {
        addEventToDigitalData('formError', {
          eventAction: this.metadata.name,
          eventCategory: 'forms',
          eventLabel: 'form error',
          formName: this.metadata.name,
          formFieldError: this.field.model.name,
          formErrorMessage: this.errors.value[0],
        });
      }
    },
  },
};
</script>
