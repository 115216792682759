/**
 * Vuelidate validations
 * @see https://vuelidate.js.org/#sub-builtin-validators
 */

import { required, minLength, maxLength, minValue, maxValue, sameAs } from '@vuelidate/validators';

import { format, parseISO } from 'date-fns';
import scRegex from './components/validations/scRegex';
import scDate from './components/validations/scDate';
import scNumberStep from './components/validations/scNumberStep';

/**
 * Collects (custom) validations of current field
 * @param {object} fieldModel Field model to get validations from
 * @returns {object} Object containing validations
 */
const getValidations = (fieldModel) => {
  const val = { value: {} };
  // required validator
  if (fieldModel.required) {
    val.value = { ...val.value, required };
  }

  // password confirm validation
  if (fieldModel.itemId === '18f8918d-6c6d-4850-b46b-d89eca1c306c') {
    val.confirmValue = {
      sameAsPassword: sameAs('value'),
    };
  }

  if (fieldModel.validationDataModels?.length > 0) {
    fieldModel.validationDataModels.forEach((valObj) => {
      // string length validator
      if (valObj.itemId === '{83E96D09-11C8-4132-8A8A-76D8DB8208D6}') {
        if (fieldModel.minLength) {
          val.value = { ...val.value, minLength: minLength(fieldModel.minLength) };
        }
        if (fieldModel.maxLength) {
          val.value = { ...val.value, maxLength: maxLength(fieldModel.maxLength) };
        }
      }

      // email validator
      if (valObj.itemId === '{9BAE3E9A-D89F-4F93-9577-68B5E9D44A38}') {
        const regex = JSON.parse(valObj.parameters).regularExpression;
        val.value = { ...val.value, email: scRegex(regex) };
      }

      // telephone number validator
      if (valObj.itemId === '{1AA74652-2177-48C9-88F5-1214445FEA34}') {
        const regex = JSON.parse(valObj.parameters).regularExpression;
        val.value = { ...val.value, telephone: scRegex(regex) };
      }

      // number validator
      if (valObj.itemId === '{163EFF9F-D173-485C-869F-41D3A46883BF}') {
        if (fieldModel.min) {
          val.value = { ...val.value, minValue: minValue(fieldModel.min) };
        }
        if (fieldModel.max) {
          val.value = { ...val.value, maxValue: maxValue(fieldModel.max) };
        }
      }

      // date validator
      if (valObj.itemId === '{24A3A1C0-CCBF-4ABA-ADA4-B6874BD3B4FC}') {
        val.value = { ...val.value, date: scDate(fieldModel.min, fieldModel.max) };
      }

      // number step validator
      if (valObj.itemId === '{A398667F-BD9F-4DDB-8C74-2501A738667D}') {
        if (fieldModel.step) {
          val.value = { ...val.value, numberStep: scNumberStep(fieldModel.step) };
        }
      }
    });
  }

  return val;
};

/**
 * Creates string replaced error messages
 * @param {string} message Original error message
 * @param {array} replacements Replacements array for the messages
 * @returns {string} String replaced message
 */
const setErrorMessage = (message, ...replacements) => {
  let msg = message;
  replacements.forEach((replacement, i) => {
    const regex = new RegExp(`\\{${i}\\}`, 'g');
    msg = msg.replace(regex, replacement);
  });
  return msg.replace(/ +/g,' ');
};

/**
 * Evaluated (custom) validation errors of current field
 * @param {object} fieldModel Field model to get validations from
 * @param {function} [translations] Optional transaltions object
 * @returns {object} Object containing validation errors
 */
const getEvaluatedErrors = (fieldModel, translations = {}) => {
  const errors = {};

  // required validator
  if (fieldModel.required) {
    if (translations?.validations?.required) {
      errors.required = setErrorMessage(translations.validations.required, fieldModel.title);
    } else {
      errors.required = setErrorMessage('{0} is required.', fieldModel.title);
    }
  }

  // password verification validator
  if (fieldModel.itemId === '18f8918d-6c6d-4850-b46b-d89eca1c306c') {
    if (translations?.validations?.sameAsPassword) {
      errors.sameAsPassword = setErrorMessage(translations.validations.sameAsPassword);
    } else {
      errors.sameAsPassword = setErrorMessage('Password and verification should be the same.');
    }
  }

  if (fieldModel.validationDataModels?.length > 0) {
    fieldModel.validationDataModels.forEach((valObj) => {
      // string length validator
      if (valObj.itemId === '{83E96D09-11C8-4132-8A8A-76D8DB8208D6}') {
        if (fieldModel.minLength) {
          errors.minLength = setErrorMessage(
            valObj.message,
            fieldModel.title,
            fieldModel.minLength,
            fieldModel.maxLength
          );
        }
        if (fieldModel.maxLength) {
          errors.maxLength = setErrorMessage(
            valObj.message,
            fieldModel.title,
            fieldModel.minLength,
            fieldModel.maxLength
          );
        }
      }

      // email validator
      if (valObj.itemId === '{9BAE3E9A-D89F-4F93-9577-68B5E9D44A38}') {
        errors.email = setErrorMessage(valObj.message, fieldModel.title);
      }

      // telephone number validator
      if (valObj.itemId === '{1AA74652-2177-48C9-88F5-1214445FEA34}') {
        errors.telephone = setErrorMessage(valObj.message, fieldModel.title);
      }

      // number validator
      if (valObj.itemId === '{163EFF9F-D173-485C-869F-41D3A46883BF}') {
        if (fieldModel.min) {
          errors.minValue = setErrorMessage(
            valObj.message,
            fieldModel.title,
            fieldModel.min,
            fieldModel.max
          );
        }
        if (fieldModel.max) {
          errors.maxValue = setErrorMessage(
            valObj.message,
            fieldModel.title,
            fieldModel.min,
            fieldModel.max
          );
        }
      }

      // date validator
      if (valObj.itemId === '{24A3A1C0-CCBF-4ABA-ADA4-B6874BD3B4FC}') {
        errors.date = setErrorMessage(
          valObj.message,
          fieldModel.title,
          `${format(parseISO(fieldModel.min), 'MM-dd-yyyy')} (mm-dd-yyyy)`,
          `${format(parseISO(fieldModel.max), 'MM-dd-yyyy')} (mm-dd-yyyy)`
        );
      }

      // number step validator
      if (valObj.itemId === '{A398667F-BD9F-4DDB-8C74-2501A738667D}') {
        if (fieldModel.step) {
          errors.numberStep = setErrorMessage(valObj.message, fieldModel.title, fieldModel.step);
        }
      }
    });
  }
  return errors;
};

export { getValidations, getEvaluatedErrors, setErrorMessage };
