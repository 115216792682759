<template>
  <div class="form-group" v-show="isVisible">
    <form-label :for="field.valueField.id" :field="field" :is-valid="isValid" />
    <select
      :id="field.valueField.id"
      v-model="value[0]"
      class="form-control"
      :class="[field.model.cssClass, { 'is-invalid': !isValid }]"
      :name="field.valueField.name"
      :required="required"
      :disabled="!isEnabled"
      @change="onInput($event.target.value)"
      @focus="field.tracker.onFocusField(field, value)"
      @blur="field.tracker.onBlurField(field, value, errors.value)"
    >
      <option v-if="field.model.showEmptyItem" value=""></option>
      <option
        v-for="item in field.model.items"
        :key="item.itemId"
        :value="item.value"
        :selected="item.selected"
      >
        {{ item.text }}
      </option>
    </select>
    <error-messages v-if="errors.value.length > 0" :errors="errors.value" />
  </div>
</template>

<script>
import BaseField from './BaseField.vue';
import FormLabel from './FormLabel.vue';

export default {
  name: 'DropdownList',
  components: {
    FormLabel,
  },
  extends: BaseField,
  methods: {
    /**
     * Updates current field state
     * @param {any} value Current field value
     * @returns void
     */
    onInput(value) {
      if (value) {
        this.value = [value];
      } else {
        this.value = [];
      }

      // validate
      this.validate();

      // update form state
      this.field.updateState(this.field.valueField.name, this.value, this.isValid);
    },
  },
};
</script>
