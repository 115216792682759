<template>
  <div>
    <h1>Pagina niet gevonden</h1>
    <p>
      Ben je even de weg kwijt? We kunnen namelijk de pagina waar je naar op zoek bent, helaas niet
      vinden. Neem gerust contact met ons op als je een probleem hebt gevonden.
    </p>
  </div>
</template>

<script>
export default {
  name: 'NotFound',
  metaInfo() {
    return {
      title: this.$t('/generic/page not found'),
    };
  },
  props: {
    context: {
      type: Object,
      default: () => {},
    },
  },
};
</script>
