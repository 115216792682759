<template>
  <label :class="$style.container">
    <span v-if="label" :class="$style.label">{{ label }}</span>
    <div :class="$style.switch">
      <input type="checkbox" :value="value" @change="$emit('update:value', $event.target.checked)" />
      <span :class="$style.slider"></span>
    </div>
  </label>
</template>

<script>
export default {
  name: 'ToggleSwitch',
  props: {
    label: {
      type: String,
      default: '',
    },
    value: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style module lang="scss">
@import '@pon/pu-styles/lib/variables.scss';

.switch {
  position: relative;
  display: flex;
  margin-bottom: 0;
  width: 2.3rem;
  height: 1.2rem;
}

.switch input {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;

  &:checked + .slider {
    background-color: $color-primary;

    &::before {
      background-color: $white;
      transform: translateX(1.1rem);
    }
  }
}

.container {
  display: flex;
  align-items: flex-end;
  margin-bottom: 0 !important;
}

.label {
  font-weight: $font-weight-normal;
  margin-right: 0.2em;
  color: $toggle-switch-label;
}

.slider {
  position: absolute;
  cursor: pointer;
  border-radius: 34px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $white;
  border: 1px solid $color-primary;
  transition: background-color 0.4 ease-in-out;

  &::before {
    transition:
      transform 0.4s ease-in,
      background-color 0.2s ease-out;
    position: absolute;
    content: '';
    height: 0.8rem;
    width: 0.8rem;
    left: 0.14rem;
    top: 0.14rem;
    background-color: $color-primary;
    border-radius: 50%;
  }
}
</style>
