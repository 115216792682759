/**
 * Vuelidate Sitecore Email validator
 * @author Rocco Janse, roccojanse@outlook.com
 */
import { helpers } from '@vuelidate/validators';

export default (param) => {
  return helpers.withParams({ type: 'regex', regex: param }, (value) => {
    const regex = new RegExp(param);
    return !helpers.req(value) || regex.test(value);
  });
};
