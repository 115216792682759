<template>
  <div :class="$style['error-component']">
    {{ error }}
  </div>
</template>

<script>
export default {
  computed: {
    error() {
      return this.$t('/forms/error messages/form submit failure');
    },
  },
};
</script>

<style module lang="scss">
.error-component {
  background-color: $color-danger;
  color: white;
  margin: 1em 0;
  padding: 0.5em;
}
</style>
