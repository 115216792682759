import { createRouter as createVueRouter, createWebHashHistory } from 'vue-router';

import RouteHandler from './RouteHandler.vue';

// support languages in the URL prefix
// e.g. /da-DK/path, or /en/path, or /path
export const routePatterns = [
  '/:lang([a-z]{2}-[A-Z]{2})/:sitecoreRoute(.*)*',
  '/:lang([a-z]{2})/:sitecoreRoute(.*)*',
  '/:sitecoreRoute(.*)*',
];

export function createRouter() {
  // create an instance of vue-router and configure routes to use the RouteHandler component
  const router = createVueRouter({
    history: createWebHashHistory(),
    routes: routePatterns.map((routePattern) => {
      return {
        path: routePattern,
        component: RouteHandler,
        props: (route) => ({
          route,
        }),
      };
    }),
  });

  router.beforeResolve((to, _, next) => {
    next();
    // event to ValTagManager
    if (typeof window !== 'undefined' && 'utag' in window) {
      const { pathname, hostname } = window.location;
      const { fullPath } = to;
      window.utag.view({
        gua_dl: `${hostname}${pathname}`,
        gua_dp: pathname === '/' ? fullPath : pathname + fullPath,
        gua_dt: `${window.document.title}`,
        gua_cd3: 'Kodiaq',
        gua_cd13: 'Kodiaq', // Should be changed when tool is going to be used for other brands
      });
    }
  });

  return router;
}
