<template>
  <animation-slide>
    <form-textarea
      v-model:value="value"
      v-show="isVisible"
      :id="field.valueField.id"
      :class="field.model.cssClass"
      :title="title"
      :name="field.valueField.name"
      :rows="field.model.rows"
      :min-length="field.model.minLength"
      :max-length="field.model.maxLength"
      :placeholder="field.model.placeholderText"
      :required="required"
      :disabled="!isEnabled"
      @input="onInput($event.target.value)"
      @focus="field.tracker.onFocusField(field, value)"
      @blur="onBlurHandler(field, value, errors.value)"
      :error-message="errors.value.join(',')"
      icon="error"
    />
  </animation-slide>
</template>

<script>
import FormTextarea from '@pon/molecule-form-textarea';
import BaseField from './BaseField.vue';

export default {
  name: 'MultiLineText',
  components: {
    FormTextarea,
  },
  extends: BaseField,
};
</script>
