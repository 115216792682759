<template>
  <sc-rich-text v-if="fields.Text" tag="div" :field="fields.Text" />
  <div v-else>[Warning: RichText component has no datasource or has an empty value]</div>
</template>

<script>
import { RichText } from '@sitecore-jss/sitecore-jss-vue';

export default {
  name: 'RichTextView',
  components: {
    ScRichText: RichText,
  },
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>
