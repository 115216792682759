<template>
  <div class="form-group" v-show="isVisible">
    <form-label :for="field.valueField.id" :field="field" :is-valid="isValid" />
    <select
      :id="field.valueField.id"
      v-model="value"
      class="form-control"
      :multiple="field.model.multipleSelection"
      :class="[field.model.cssClass, { 'is-invalid': !isValid }]"
      :name="field.valueField.name"
      :required="required"
      :disabled="!isEnabled"
      @change="onInput"
      @focus="field.tracker.onFocusField(field, value)"
      @blur="field.tracker.onBlurField(field, value, errors.value)"
    >
      <option v-if="field.model.showEmptyItem" value=""></option>
      <option v-for="item in field.model.items" :key="item.itemId" :value="item.value">
        {{ item.text }}
      </option>
    </select>
    <error-messages v-if="errors.value.length > 0" :errors="errors.value" />
  </div>
</template>

<script>
import BaseField from './BaseField.vue';
import FormLabel from './FormLabel.vue';

export default {
  name: 'ListBox',
  components: {
    FormLabel,
  },
  extends: BaseField,
  data() {
    return {
      value: !this.field.model.multipleSelection ? this.field.value[0] : this.field.value,
      isValid: true,
    };
  },
  methods: {
    /**
     * Updates current field state
     * @param {any} value Current field value
     * @returns void
     */
    onInput() {
      const newValue = [];
      // get selected options
      const options = this.$el.querySelectorAll('option');
      options.forEach((option) => {
        if (option.selected) {
          newValue.push(option.value);
        }
      });

      // update value based on multiple selection
      this.value = !this.field.model.multipleSelection ? newValue[0] : newValue;

      // validate
      this.validate();

      // update form state
      this.field.updateState(this.field.valueField.name, this.value, this.isValid);
    },
  },
};
</script>
