<template>
  <animation-slide>
    <model-select
      v-model:value="selectedModel"
      :model-selection-datasource-id="modelSelectionDatasourceId"
      :placeholder="placeholderText"
      :label="title"
      :error="errors.value.join(',')"
      :required="required"
    />
  </animation-slide>
</template>

<script>
import ModelSelect from '../../Library/ModelSelect/ModelSelect.vue';
import BaseField from './BaseField.vue';

export default {
  name: 'JSSModelSelect',
  components: {
    ModelSelect,
  },
  extends: BaseField,
  data() {
    return {
      selectedModel: null,
    };
  },
  computed: {
    placeholderText() {
      return this.field?.model?.placeholderText;
    },
    modelSelectionDatasourceId() {
      return this.field?.model?.modelSelectionDatasource;
    },
  },
  watch: {
    selectedModel(newValue) {
      this.onInput(JSON.stringify(newValue));
      this.$emit('change');
    },
  },
};
</script>
