<template>
  <div>
    <button
      :id="field.buttonField.id"
      type="submit"
      class="btn"
      :class="field.model.cssClass"
      :value="field.model.title"
      :name="field.buttonField.name"
      :disabled="!isEnabled"
      v-show="isVisible"
      @click="onClick(field.buttonField.name, field.model.navigationStep)"
      @keyup="onClick(field.buttonField.name, field.model.navigationStep)"
    >
      {{ field.model.title }}
    </button>
    <input type="hidden" :name="field.buttonField.name" :value="pageId" />
  </div>
</template>

<script>
import BaseField from './BaseField.vue';

export default {
  name: 'SubmitButton',
  extends: BaseField,
  data() {
    return {
      pageId: null,
    };
  },
  methods: {
    /**
     * Let Sitecore know which button is clicked
     * @param {any} buttonName Current button name
     * @returns void
     */
    onClick(buttonName, navigationStep = null) {
      // update form state
      this.field.setSubmitButtonName(buttonName, navigationStep);
    },
    gotopage(pageId, conditionResult) {
      if (conditionResult) {
        this.pageId = pageId;
        // console.log('GOTOPAGE', pageId, conditionResult);
      }
    },
  },
};
</script>
