<template>
  <div data-qa="error-handler">
    <div>
      <span>{{ message }}</span>

      <button v-if="message" type="button" @click="dismiss">
        x
        <span>{{ $t('/generic/error message/close') }}</span>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      message: '',
    };
  },
  created() {
    this.$eventBus.on('axios-interceptor-throws-error', (error) => this.handleError(error));
  },
  methods: {
    handleError(err) {
      this.message = err.message;
    },
    dismiss() {
      this.message = '';
    },
  },
};
</script>

<style module></style>
