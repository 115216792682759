<template>
  <div v-if="models && models.length" :class="$style['model-select']">
    <div v-if="label" :class="$style['label']">{{ label }}</div>
    <span v-if="!required" :class="$style['optional-field']"
      >{{ $t('/generic/optional field') }}
    </span>

    <div :class="$style['input-wrapper']">
      <app-icon :class="$style.icon" icon="models" width="28" height="28" aria-hidden="true" />
      <select
        v-model="selectedModelId"
        @change="onChange"
        :class="[$style.select, { [$style['initial-style']]: !initialStyle }]"
      >
        <option v-if="placeholder" :value="null" disabled>{{ placeholder }}</option>
        <option
          v-for="model in models"
          :key="`option-model-${model.code ? model.code : model.id}`"
          :value="model.code ? model.code : model.id"
        >
          {{ model.name }}
        </option>
      </select>
    </div>
    <error-message :text="error" />
  </div>
</template>

<script>
import { QueryParameterNames } from '@/constants.js';
import { computed, ref, watch } from 'vue';
import { useQuery } from '@vue/apollo-composable';
import modelQuery from './ModelSelectQuery.graphql';
import urlParams from '@/plugins/url-params';
import AppIcon from '@pon/pu-atom-app-icon';
import ErrorMessage from '@pon/pu-atom-error-message';
import gql from 'graphql-tag';

const mapModel = (jssModel) => ({
  id: jssModel.modelId?.value,
  code: jssModel.modelCode?.value,
  name: jssModel.modelName?.value,
  imageUrl: jssModel.image?.jss?.value?.src,
  thumbnailImageUrl: jssModel.thumbnailImage?.jss?.value?.src,
});

export default {
  name: 'ModelSelect',
  components: {
    AppIcon,
    ErrorMessage,
  },
  props: {
    modelSelectionDatasourceId: {
      type: String,
      required: true,
    },
    value: {
      type: [String, Object],
      default: '',
    },
    error: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  emits:['update:value'],
  setup(props, { emit }) {
    const modelsList = ref([]);
    const initialStyle = ref(props.value);
    const models = computed(() => modelsList.value);
    const selectedModelId = ref('');
    const selectedModel = computed(() =>
      modelsList.value.find(
        (model) => model.code === selectedModelId.value || model.id === selectedModelId.value
      )
    );

    // emit input event upon changing selectedModel
    const onChange = () => {
      const value = selectedModel.value
        ? {
            id: selectedModel.value.id,
            code: selectedModel.value.code,
            name: selectedModel.value.name,
          }
        : null;
      emit('update:value', value);

      initialStyle.value = value;

      // Backwards compatibility: Set the model code as selectedModelId if the model has a value for it, otherwise fallback to the model id.
      selectedModelId.value = selectedModel?.value?.code
        ? selectedModel?.value?.code
        : selectedModel?.value?.id;
    };

    const { result } = useQuery(
      gql`
        ${modelQuery}
      `,
      { id: props.modelSelectionDatasourceId },
      { fetchPolicy: 'cache-and-network' }
    );

    watch(result, (value) => {
      const sitecoreModels = value.item?.models?.targetItems;
      const mapModels = sitecoreModels ? sitecoreModels.map(mapModel) : [];
      modelsList.value = mapModels;

      // check URL query parameters for constant MODEL_CODE or INTERESTED_IN_VEHICLE_MODEL and preselect a model based on that value.
      selectedModelId.value =
        urlParams.get(QueryParameterNames.MODEL_CODE) ||
        urlParams.get(QueryParameterNames.INTERESTED_IN_VEHICLE_MODEL) ||
        null;
    });

    watch(selectedModel, () => {
      emit('update:value', {
        id: selectedModel?.value?.id,
        code: selectedModel.value.code,
        name: selectedModel?.value?.name,
      });

      selectedModelId.value = selectedModel?.value?.code
        ? selectedModel?.value?.code
        : selectedModel?.value?.id;
    });

    return {
      initialStyle,
      models,
      onChange,
      selectedModel,
      selectedModelId,
    };
  },
};
</script>

<style lang="scss" module>
$form-select-border: 2px solid $gray-light !default;
$form-select-border-bottom: $form-select-border !default;
$form-select-arrow-size: 0.5rem !default;
$form-select-arrow-border-size: 2px !default;
$model-select-icon-margin-top-mobile: 0.7rem;

.label {
  @include label;
}

.optional-field {
  color: $gray;
  font-weight: $font-weight-normal;
  font-size: $font-size-m;
  margin-left: 0.3125rem;
}

.model-select {
  margin-bottom: 1em;
}

.input-wrapper {
  position: relative;
  flex: 1 1 auto;
  border: none; // $form-select-border;
  border-bottom: $form-select-border-bottom;
  font-size: 0.75rem; // same as button next to it
  font-weight: normal;
  color: $text-color;

  @include breakpoint-max(md) {
    margin-left: $spacing-xs;
  }

  & svg {
    position: absolute;
  }

  &::before,
  &::after {
    content: '';
    display: block;
    position: absolute;
    pointer-events: none;
  }

  &::before {
    // arrow-background
    top: 0;
    right: 0;
    height: 100%;
    width: 2rem;
    background: $model-select-icon-bg-color;
  }

  &::after {
    // arrow
    top: 0.7rem;
    right: 1rem;
    width: $form-select-arrow-size;
    height: $form-select-arrow-size;
    border: $form-select-arrow-border-size solid;
    border-left: 0;
    border-top: 0;
    color: $model-select-icon-color;
    transform: translate(50%, -50%) rotate(45deg);

    @include breakpoint-max(md) {
      top: $model-select-icon-margin-top-mobile;
    }
  }

  &:focus-within {
    &::before {
      background: $model-select-icon-bg-focused;
    }

    &::after {
      color: $model-select-icon-focused;
    }
  }
}

.icon {
  position: absolute;
  left: 0.5rem;
  top: 50%;
  transform: translateY(-50%);
  color: $model-select-car-icon;
  max-width: 32px;
  max-height: 32px;
}

.select {
  height: 100%;
  width: 100%;
  border: none;
  padding: $spacing-xs 0 $spacing-xs 4em; // we need custom padding-left for margin with icon
  appearance: none;
  color: $model-select-color;
  font: inherit;
  background-color: $model-select-background-color;
}

.select.initial-style {
  color: $gray-light;
  font-size: 1rem;
  font-weight: normal;
  padding: $spacing-xs 0 $spacing-xs 3em;

  option {
    height: 100%;
    width: 100%;
    border: none;
    padding: $spacing-xs 0 $spacing-xs 4em;
    appearance: none;
    color: $text-color;
    font-size: 0.75rem;
  }
}
</style>
