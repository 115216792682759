<template>
  <pon-subdomain-header v-if="subdomain.enabled" :uri="subdomain.uri" :brand="subdomain.brand"/>
  <div :class="{ 'app-root': subdomain.enabled }">
    <context-view :visible="contextViewVisible" />
    <!-- 'router-view' is a "global" component that is injected into the Vue component registry by vue-router. -->
    <router-view v-if="!languageIsChanging" />
    <div v-if="languageIsChanging" class="alert alert-info">
      <app-loader />
    </div>
  </div>
  <pon-subdomain-footer v-if="subdomain.enabled" :uri="subdomain.uri" :brand="subdomain.brand"/>
</template>

<script>
import AppLoader from '@pon/pu-atom-app-loader';
import ContextView from './ContextView';
import { PonSubdomainHeader, PonSubdomainFooter } from "@pon/subdomain";
import GlobalVariables from "./helpers/GlobalVariables";

export default {
  name: 'AppRoot',
  data() {
    return {
      languageIsChanging: false,
      contextViewVisible: false,
      subdomain: {
        enabled: false,
        brand: GlobalVariables?.variables?.$settings?.brand || "vwlkw",
        uri: `${process.env.VUE_APP_APIM_HOST}${process.env.VUE_APP_APIM_CONTENT_PATH}`
      },
    };
  },
  created() {
    this.subdomain.enabled = GlobalVariables?.variables?.$settings?.subdomain.enabled ?? false;
  },
  methods: {
    changeAppLanguage(language) {
      const i18n = this.$i18n;
      if (i18n.locale !== language) {
        this.languageIsChanging = true;
      }
    },
    toggleContextView() {
      this.contextViewVisible = !this.contextViewVisible;
    },
  },
  provide() {
    // Use Vue's provide/inject capabilities to "provide" functions to
    // any descendant component that want to use/"inject" the functions.
    return {
      changeAppLanguage: this.changeAppLanguage,
      toggleContextView: this.toggleContextView,
    };
  },
  components: {
    ContextView,
    AppLoader,
    PonSubdomainHeader,
    PonSubdomainFooter
  },
};
</script>

<style lang="postcss" scoped>
.app-root {
  margin: 1em 3em;
  min-height: 100dvh;
}
</style>