<template>
  <div class="form-group" v-show="isVisible">
    <form-label :for="field.valueField.id" :field="field" :is-valid="isValid" />
    <input
      :id="field.valueField.id"
      v-model="value"
      type="number"
      class="form-control"
      :class="[field.model.cssClass, { 'is-invalid': !isValid }]"
      :name="field.valueField.name"
      :step="field.model.step"
      :min="field.model.min"
      :max="field.model.max"
      :placeholder="field.model.placeholderText"
      :required="required"
      :disabled="!isEnabled"
      @change="onInput($event.target.value)"
      @focus="field.tracker.onFocusField(field, value)"
      @blur="field.tracker.onBlurField(field, value, errors.value)"
    />
    <error-messages v-if="errors.value.length > 0" :errors="errors.value" />
  </div>
</template>

<script>
import BaseField from './BaseField.vue';
import FormLabel from './FormLabel.vue';

export default {
  name: 'NumberField',
  components: {
    FormLabel,
  },
  extends: BaseField,
  created() {
    // sanitize attributes
    if (this.field.model.min) {
      this.field.model.min = this.field.model.min > 0 ? this.field.model.min : null;
    }
    if (this.field.model.max) {
      this.field.model.max = this.field.model.max > 0 ? this.field.model.max : null;
    }
    if (this.field.model.step) {
      this.field.model.step = this.field.model.step > 0 ? this.field.model.step : null;
    }
  },
};
</script>
