const helpers = {
  normalize: (value, preserveCase) => {
    if (value === null) {
      return '';
    }
    return preserveCase ? String(value) : String(value).toLowerCase();
  },
  toNumber: (value) => {
    const nValue = Number(value);
    // eslint-disable-next-line no-restricted-globals
    return isNaN(nValue) ? undefined : nValue;
  },
  indexOf: (str, value, startIndex, preserveCase) => {
    const nStr = helpers.normalize(str, preserveCase);
    const nValue = helpers.normalize(value, preserveCase);
    return nValue.indexOf(nStr, startIndex);
  },
  endsWith: (str, value, preserveCase) => {
    const nStr = helpers.normalize(str, preserveCase);
    const nValue = helpers.normalize(value, preserveCase);
    const lengthDiff = nStr.length - nValue.length;
    return lengthDiff >= 0 && nStr.substring(lengthDiff) === nValue;
  },
};

export default helpers;
