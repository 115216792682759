<template>
  <div class="form-group" v-show="isVisible">
    <form-label :for="field.valueField.id" :field="field" :is-valid="isValid" />
    <input
      :id="field.valueField.id"
      v-model="value"
      type="password"
      class="form-control"
      :class="[field.model.cssClass, { 'is-invalid': !isValid }]"
      :name="field.valueField.name"
      :minLength="field.model.minLength"
      :maxLength="field.model.maxLength"
      :placeholder="field.model.placeholderText"
      :required="required"
      :disabled="!isEnabled"
      @input="onInput($event.target.value)"
      @focus="field.tracker.onFocusField(field, value)"
      @blur="field.tracker.onBlurField(field, value, errors.value)"
    />
    <error-messages v-if="errors.value.length > 0" :errors="errors.value" />

    <label
      :for="confirmId"
      class="form-label"
      :class="[field.model.labelCssClass, { 'is-invalid': !isConfirmValid }]"
    >
      <span v-html="field.model.confirmPasswordLabel" />
      <span class="required-asterisk" v-if="field.model.required">*</span>
    </label>
    <input
      :id="confirmId"
      v-model="confirmValue"
      type="password"
      class="form-control"
      :class="[field.model.cssClass, { 'is-invalid': !isConfirmValid }]"
      :name="confirmName"
      :minLength="field.model.minLength"
      :maxLength="field.model.maxLength"
      :placeholder="field.model.confirmPasswordPlaceholder"
      :required="field.model.required"
      @input="onInputConfirm($event.target.value)"
      @focus="
        field.tracker.onFocusField({ ...field, valueField: { name: confirmName } }, confirmValue)
      "
      @blur="
        field.tracker.onBlurField(
          { ...field, valueField: { name: confirmName } },
          confirmValue,
          errors.confirmValue
        )
      "
    />
    <error-messages v-if="errors.confirmValue.length > 0" :errors="errors.confirmValue" />
  </div>
</template>

<script>
import BaseField from './BaseField.vue';
import FormLabel from './FormLabel.vue';

export default {
  name: 'PasswordConfirmation',
  components: {
    FormLabel,
  },
  extends: BaseField,
  data() {
    return {
      confirmId: null,
      confirmName: null,
      confirmValue: '',
      isConfirmValid: true,
      errors: { value: [], confirmValue: [] },
    };
  },
  mounted() {
    // set confirm id and name
    this.confirmId = this.field.valueField.name.replace('__Value', '__ConfirmPassword');
    this.confirmName = this.field.valueField.name.replace('.Value', '.ConfirmPassword');

    // add confirm field to the form state
    this.field.addToFormState(
      this.confirmName,
      this.confirmValue,
      this.isConfirmValid,
      this.isEnabled,
      this.isVisible
    );
  },
  methods: {
    /**
     * Validates current field and confirm field
     * @returns {boolean} true|false
     */
    validate() {
      let isValid = true;
      let isConfirmValid = true;

      if (this.v$?.value) {
        this.v$.value.$touch();
        isValid = !this.v$.value.$error;
        this.getValidationErrors(this.v$.value, 'value');
      }
      if (this.v$?.confirmValue) {
        this.v$.confirmValue.$touch();
        isConfirmValid = !this.v$.confirmValue.$error;
        this.getValidationErrors(this.v$.confirmValue, 'confirmValue');
      }

      this.isValid = isValid;
      this.isConfirmValid = isConfirmValid;
      return this.isValid && this.isConfirmValid;
    },
    /**
     * Resets and validates current field after form submit
     * this normally shouldn't happen. Fail-save method.
     * @returns {boolean} true|false
     */
    resetValidation() {
      this.value = '';
      this.confirmValue = '';
      this.validate();
    },
    /**
     * Updates current confirm field state
     * @param {any} value Current field value
     * @returns void
     */
    onInputConfirm(value) {
      this.confirmValue = value;

      // validate
      this.validate();

      // update form state
      this.field.updateState(
        this.confirmName,
        this.confirmValue,
        this.isConfirmValid,
        this.isEnabled,
        this.isVisible
      );
    },
  },
};
</script>
