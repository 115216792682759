<template>
  <form
    method="post"
    :action="action"
    :novalidate="novalidate"
    :autocomplete="autocomplete"
    @submit="onFormSubmit"
  >
    <component
      :is="component.component"
      v-for="component in components"
      :key="component.props.key"
      :ref="component.props.ref"
      :field="component.props"
      @change="getState"
      :metadata="form.metadata"
      @updateFieldRefs="setFieldRefs"
    />
  </form>
</template>

<script>
import {
  serializeForm,
  instanceOfValueFormField,
  getFieldValueFromModel,
  submitForm,
  FormTracker,
  FormFetcher,
  TrackerFetcher,
} from '@sitecore-jss/sitecore-jss-forms';
import FieldFactory from './FieldFactory';
import Conditions from './Conditions';
import { markRaw } from 'vue';

export default {
  name: 'VueForm',
  props: {
    form: {
      type: Object,
      default: () => ({}),
    },
    sitecoreApiKey: {
      type: String,
      default: null,
    },
    sitecoreApiHost: {
      type: String,
      default: null,
    },
    params: {
      type: Object,
      default: null,
    },
    translations: {
      type: Object,
      default: () => ({}),
    },
    apiEndpoint: {
      type: String,
      default: null,
    },
    novalidate: {
      type: Boolean,
      default: true,
    },
    autocomplete: {
      type: String,
      default: 'on',
    },
    customFieldFactory: {
      type: Function,
      default: null,
    },
    customTrackerFetcher: {
      type: Object,
      default: null,
    },
    trackerEndpoint: {
      type: String,
      default: null,
    },
    onSubmit: {
      type: Function,
      default: null,
    },
    onAfterSubmit: {
      type: Function,
      default: null,
    },
  },
  data() {
    return {
      action: '',
      components: {},
      formState: {},
      conditions: {},
      submitButtonName: null,
      navigationStep: null,
      nextForm: null,
      errors: null,
      FormTracker: null,
      trackerFetcher: this.customTrackerFetcher || TrackerFetcher,
      fieldFactory: this.customFieldFactory || FieldFactory,
      fieldsRefs: [],
      fieldReferences: {},
    };
  },
  computed: {
    apiFormsEndpoint() {
      let apiEndpoint = '';
      if (this.apiEndpoint) {
        apiEndpoint = this.apiEndpoint;
      } else if (this.sitecoreApiHost) {
        apiEndpoint = `${this.sitecoreApiHost}/api/jss/formbuilder`;
      } else {
        console.warn('Form: No sitecoreApiHost or apiEndpoint set.');
      }
      return apiEndpoint;
    },
    apiTrackerEndpoint() {
      let apiEndpoint = '';
      if (this.trackerEndpoint) {
        apiEndpoint = this.trackerEndpoint;
      } else if (this.sitecoreApiHost) {
        apiEndpoint = `${this.sitecoreApiHost}/api/jss/fieldtracking/register`;
      } else {
        console.warn('Form: No sitecoreApiHost or trackerEndpoint set.');
      }
      return apiEndpoint;
    },
    customParams() {
      // create custom param string if set
      let params = '';
      if (this.params) {
        Object.keys(this.params).forEach((param) => {
          params = `${params}&${param}=${this.params[param]}`;
        });
      }
      return params;
    },
  },
  mounted() {
    let endpoint = '';
    if (this.sitecoreApiKey) {
      endpoint = `${endpoint}?sc_apikey=${this.sitecoreApiKey}${this.customParams}`;
    } else {
      endpoint = `${this.apiTrackerEndpoint}${this.customParams}`;
    }

    // init the form tracker
    this.FormTracker = new FormTracker({
      endpoint,
      fetcher: this.trackerFetcher,
    });

    // render the form
    this.renderForm(this.form);
  },
  methods: {
    setFieldRefs(item) {
      let fieldReferences = {};
      const mergedObj = { ...this.fieldRefs, ...item };
      this.fieldsRefs.push(mergedObj);

      for (let i = 0; i < this.fieldsRefs.length; i++) {
        for (let key in this.fieldsRefs[i]) {
          let isDuplicate = false;
          for (let j = i + 1; j < this.fieldsRefs.length; j++) {
            if (key in this.fieldsRefs[j]) {
              isDuplicate = true;
              break;
            }
          }
          if (!isDuplicate) {
            fieldReferences[key] = this.fieldsRefs[i][key];
          }
        }
      }

      this.fieldReferences = fieldReferences;
    },
    getState() {
      this.$emit('formState', this.formState);
    },
    /**
     * Submits the form
     * @param {event} e Submit event
     * @returns void
     */
    async onFormSubmit(e) {
      e.preventDefault();
      const { currentTarget } = e;

      const submitButton = currentTarget.querySelector('button[type=submit]');
      submitButton.disabled = true;
      submitButton.setAttribute('data-loading', 'true');
      this.$emit('form-submit');

      // check all referenced components for validation issues
      // but only when going to the next form page or when
      // actually submitting the form
      if (this.navigationStep >= 0) {
        Object.keys(this.fieldReferences).forEach((fieldKey) => {
          if (this.fieldReferences?.[fieldKey]?.[0]) {
            const isValid = this.fieldReferences[fieldKey][0].validate();
            // update state if field is a field with a state
            if (this.formState?.[fieldKey]?.isValid) {
              this.formState[fieldKey].isValid = isValid;
            }
          }
        });
      }

      try {
        await this.$recaptchaLoaded();
        const token = await this.$recaptcha('login');

        Object.keys(this.formState).forEach((fieldKey) => {
          if (this.formState[fieldKey].fieldName === 'Captcha') {
            this.updateFieldState(fieldKey, token);
          }
        });
      } catch (error) {
        console.warn('Something went wrong with google recaptcha', error);
      }

      // we need to convert an empty array to an empty value because the "mergeOverwritingExisting"
      // method doen't currently override the values with an empty array (bug?)
      const fieldValues = {};
      Object.keys(this.formState).forEach((fieldKey) => {
        if (
          Array.isArray(this.formState[fieldKey].value) &&
          this.formState[fieldKey].value.length < 1
        ) {
          fieldValues[fieldKey] = '';
        } else {
          fieldValues[fieldKey] = this.formState[fieldKey].value;
        }
      });

      // if onSubmit is defined
      if (this.onSubmit) {
        this.onSubmit(e, fieldValues);
      }

      // serialize the form data that we got from the server
      // (hidden fields with constant values, unchanged default field values, etc)
      const formData = serializeForm(this.nextForm || this.form, {
        submitButtonName: this.submitButtonName,
      });

      // merge in user-updated field values
      formData.mergeOverwritingExisting(fieldValues);

      // submit the form
      try {
        const result = await submitForm(formData, this.action, {
          fetcher: FormFetcher,
        });

        if (this.onAfterSubmit) {
          this.onAfterSubmit(result);
        }

        if (result.success) {
          this.$emit('submit-success', this.formState);

          // Errors from Salesforce (even when success is true)
          if (result.errors && Object.values(result.errors).length) {
            this.$emit('submit-form-error', result.errors);
          }
          // redirect
          const resUrl = result.redirectUrl;

          if (resUrl) {
            // if onRedirect action.
            if (this.onRedirect) {
              this.onRedirect(resUrl);
            } else if (this.$router) {
              try {
                window.location.href = new URL(resUrl);
              } catch {
                // only use this router when the resUrl is relative path
                this.$router.push({ path: resUrl });
              }
            } else {
              window.location.href = resUrl;
            }
          }

          // next page
          if (result.nextForm) {
            this.nextForm = result.nextForm;
            this.renderForm(this.nextForm);
          }

          // just reset form state
          if (!result.nextForm) {
            this.resetFormState();
          }
        }

        if (!result.success) {
          if (Object.keys(result.validationErrors).length) {
            const errors = {};
            Object.keys(result.validationErrors).forEach((fieldKey) => {
              const errorField = this.fieldReferences[fieldKey][0];
              errors[fieldKey] = errorField;
              errors[fieldKey].resetValidation();
            });
            this.$emit('submit-fields-errors', errors);
          }
          // other errors
          if (result.errors && result.errors.length) {
            this.errors = result.errors;
          }
        }
      } catch (error) {
        if (Array.isArray(error)) {
          this.errors = error;
        } else if (typeof error === 'string') {
          console.warn('Form submit error', error);
          this.errors = [error];
        } else {
          console.warn('Form submit error', error);
          this.errors = [error.message];
        }
        this.$emit('submit-form-error', error);
      }
      submitButton.disabled = false;
      submitButton.setAttribute('data-loading', 'false');
    },
    /**
     * Update field state and applies conditions
     * @param {string} key Name of field
     * @param {string} value Current field value
     * @param {boolean} [isValid = true] True or fasle
     * @param {boolean} [isEnabled = true] True or fasle
     * @param {boolean} [isVisible = true] True or fasle
     */
    updateFieldState(key, value, isValid = true, isEnabled = true, isVisible = true) {
      this.formState[key].value = value;
      this.formState[key].isValid = isValid;
      this.formState[key].isEnabled = isEnabled;
      this.formState[key].isVisible = isVisible;

      this.applyConditions();
    },
    /**
     * Resets form state
     * @returns void
     */
    resetFormState() {
      Object.keys(this.formState).forEach((fieldKey) => {
        this.formState[fieldKey].value = '';
        this.formState[fieldKey].isValid = true;
      });
    },
    /**
     * Renders the form
     * @param {object} renderForm Current form data object
     * @returns void
     */
    renderForm(renderForm) {
      if (!renderForm) {
        console.warn(`Form: No form data was provided. Need to set a datasource?`);
        return;
      }
      if (!renderForm.metadata) {
        console.warn(`Form: Data invalid. Forget to set the rendering contents resolver?`);
        return;
      }

      if (this.sitecoreApiKey) {
        this.action = `${this.apiFormsEndpoint}?fxb.FormItemId=${renderForm.metadata.itemId}&fxb.HtmlPrefix=${renderForm.htmlPrefix}&sc_apikey=${this.sitecoreApiKey}${this.customParams}`;
      } else {
        this.action = `${this.apiFormsEndpoint}?fxb.FormItemId=${renderForm.metadata.itemId}&fxb.HtmlPrefix=${renderForm.htmlPrefix}${this.customParams}`;
      }

      this.FormTracker.setFormData(
        renderForm.formItemId.value,
        renderForm.formSessionId.value,
        renderForm.metadata.isTrackingEnabled
      );

      this.components = markRaw(this.createComponentsFromFields(renderForm.fields));

      this.$nextTick(() => {
        this.applyConditions();
      });
    },
    /**
     * Helper to recursively create components from fields
     * @param {array} fields Field data
     * @returns {array} Array of component objects
     */
    createComponentsFromFields(fields) {
      return fields.map(this.createComponent);
    },
    /**
     * Identify to Sitecore which button is clicked
     * @param {string} buttonName Name (Sitecore key) if the button
     * @param {number} [navStep] -1 for previous form, 1 for next form
     * @returns void
     */
    setSubmitButtonName(buttonName, navStep = null) {
      this.submitButtonName = buttonName;
      this.navigationStep = navStep;
    },
    /**
     * Adds field to form state
     * @param {string} name Name of the field
     * @param {any} value Field value
     * @param {string} fieldName Field name on sitecore
     * @param {boolean} isValid Field validation status
     * @param {boolean} isEnabled Field validation status
     * @param {boolean} isVisible Field validation status
     * @returns void
     */
    addToFormState(name, fieldName, value, isValid, isEnabled, isVisible) {
      if (!this.formState?.[name]) {
        this.formState[name] = { fieldName, value, isValid, isEnabled, isVisible };
      }
    },
    /**
     * Creates component based on field data
     * @param {object} field Field data from Sitecore
     * @returns {object} Contains component data and properties
     */
    createComponent(field) {
      // recursive components
      const fields = field.fields ? this.createComponentsFromFields(field.fields) : null;

      // create field props
      const props = {
        ...field,
        fields,
        key: field.model.itemId,
        ref: field?.valueField?.name || field.model.conditionSettings.fieldKey,
        updateState: this.updateFieldState,
        setSubmitButtonName: this.setSubmitButtonName,
        addToFormState: this.addToFormState,
        tracker: this.FormTracker,
        translations: this.translations,
      };

      // add default value or value from the state
      if (instanceOfValueFormField(field) && field.valueField.name) {
        const { name } = field.valueField;
        const value = this.formState[field.valueField.name]?.value || getFieldValueFromModel(field);
        const isValid = this.formState[field.valueField.name]?.isValid || true;
        const isEnabled = this.formState[field.valueField.name]?.isEnabled || true;
        const isVisible = this.formState[field.valueField.name]?.isVisible || true;

        // add to props
        props.name = name;
        props.value = value;
        props.isValid = isValid;
        props.isEnabled = isEnabled;
        props.isVisible = isVisible;

        // add fieldstate to state
        this.addToFormState(
          field.valueField.name,
          field.model.name,
          value,
          isValid,
          isEnabled,
          isVisible
        );
      }

      // conditions
      if (field.model?.conditionSettings?.fieldConditions) {
        field.model.conditionSettings.fieldConditions.forEach((fieldCondition) => {
          // only if there are (some) actions
          if (fieldCondition?.actions && fieldCondition.actions.length > 0) {
            const { fieldKey } = field.model.conditionSettings;
            // add to conditions
            if (this.conditions[fieldKey]) {
              this.conditions[fieldKey].push(fieldCondition);
            } else {
              this.conditions[fieldKey] = [fieldCondition];
            }
          }
        });
      }

      return {
        component: this.fieldFactory(field.model.fieldTypeItemId),
        props,
      };
    },

    /**
     * Returns field ref by condition field key
     * @param {string} fieldId Condition field key
     * @returns {null|object} Referenced component, if found
     */
    getFieldRefByConditionFieldKey(fieldId) {
      let component = null;
      const result = Object.values(this.fieldReferences).filter((ref) => {
        return (
          typeof ref[0] !== 'undefined' && ref[0].field.model.conditionSettings.fieldKey === fieldId
        );
      });
      if (result.length > 0) {
        [[component]] = result;
      }
      return component;
    },

    /**
     * Get value of referenced field component
     * and send it to the condition operator function
     * @param {object} condition Condition object
     * @returns {boolean} true or false
     */
    isConditionSatisfied(condition) {
      let result = false;
      const targetRef = this.getFieldRefByConditionFieldKey(condition.fieldId);
      if (targetRef) {
        const operatorFn = Conditions.operatorFactory(condition.operatorId);
        result = operatorFn(targetRef.value, condition.value);
      }
      return result;
    },

    /**
     * Evaluate field conditions based on match type
     * @param {object} fieldCondition Field conditions object
     * @returns {boolean} true or false
     */
    evaluateCondition(fieldCondition) {
      const matchType = Conditions.matchTypeFactory(fieldCondition.matchTypeId);
      return matchType === 'all'
        ? fieldCondition.conditions.every(this.isConditionSatisfied)
        : fieldCondition.conditions.some(this.isConditionSatisfied);
    },

    /**
     * Applies all field conditions
     * @returns void
     */
    applyConditions() {
      Object.values(this.conditions).forEach((fieldConditions) => {
        fieldConditions.forEach((fieldCondition) => {
          const conditionResult = this.evaluateCondition(fieldCondition);
          fieldCondition.actions.forEach((action) => {
            const targetRef = this.getFieldRefByConditionFieldKey(action.fieldId);
            if (targetRef) {
              const actionFn = Conditions.actionTypeFactory(action.actionTypeId, conditionResult);
              // execute action
              targetRef[actionFn](action.value, conditionResult);
            }
          });
        });
      });
    },
  },
};
</script>

<style>
.required-asterisk {
  padding-left: 0.25rem;
  color: #ff0000;
}
</style>
