<template>
  <animation-slide>
    <form-input
      v-model:value="value"
      type="hidden"
      :id="field.valueField.id"
      :name="field.model.name"
      :title="title"
      :class="field.model.cssClass"
      :disabled="!isEnabled"
      v-show="false"
    />
  </animation-slide>
</template>

<script>
import BaseField from './BaseField.vue';
import FormInput from '@pon/pu-molecule-form-input';

export default {
  extends: BaseField,
  data() {
    return {
      defaultValue: '',
    };
  },
  components: {
    FormInput,
  },
  watch: {
    isEnabled(val) {
      // Custom logic so that when the hiddenField is disabled it doesn't have a value

      if (val === true) {
        // Reset the value to the defaultValue
        if (this.defaultValue) {
          this.onInput(this.defaultValue);
        }
      } else {
        // Save the previous value (this the default value) and set the value to an empty string
        this.defaultValue = this.value;

        this.field.updateState(
          this.field.valueField.name,
          '',
          true, // Set IsValid to true
          this.isEnabled,
          this.isVisible
        );
      }
    },
  },
};
</script>
