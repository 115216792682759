<script>
import useVuelidate from '@vuelidate/core';
import { getValidations, getEvaluatedErrors } from '../../Validation';
import ErrorMessages from '../ErrorMessages.vue';

export default {
  name: 'BaseField',
  components: {
    ErrorMessages,
  },
  props: {
    field: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      value: this.field.value,
      isValid: true,
      isVisible: true,
      isEnabled: true,
      required: this.field.model.required,
      errors: { value: [] },
    };
  },
  validations() {
    // set vuelidate validations
    return getValidations(this.field.model);
  },
  computed: {
    evaluatedErrors() {
      return getEvaluatedErrors(this.field.model, this.field.translations);
    },
  },
  created() {
    this.v$ = useVuelidate();
    // sanitize attributes
    /* eslint-disable */
    if (this.field.model.minLength) {
      this.field.model.minLength =
        this.field.model.minLength > 0 ? this.field.model.minLength : null;
    }
    if (this.field.model.maxLength) {
      this.field.model.maxLength =
        this.field.model.maxLength > 0 ? this.field.model.maxLength : null;
    }
  },
  methods: {
    /**
     * Validates current field
     * @returns {boolean} true|false
     */
    validate() {
      let isValid = true;
      if (this.v$.value) {
        this.v$.value.$touch();
        isValid = !this.v$.value.$error;
        this.getValidationErrors(this.v$.value);
      }
      this.isValid = isValid;
      return this.isValid;
    },
    /**
     * Adds evaluated errors to the errors array
     * to render the errors but also to provide the
     * tracking with errors occured.
     * @param {object} valObj Validations object
     * @param {string} [key = 'value'] Optional key name for multpiple validations
     */
    getValidationErrors(valObj, key = 'value') {
      this.errors[key] = [];

      if (valObj) {
        const msgKey = Object.keys(this.evaluatedErrors).find((errKey) => {
          return valObj.value[errKey] && valObj.value[errKey].$invalid === true;
        });
        if (msgKey) {
          this.errors[key].push(this.evaluatedErrors[msgKey]);
        }
      }
    },
    /**
     * Resets and validates current field after form submit
     * this normally shouldn't happen. Fail-save method.
     * @returns {boolean} true|false
     */
    resetValidation() {
      this.validate();
    },
    /**
     * Updates current field state
     * @param {any} value Current field value
     * @returns void
     */
    onInput(value) {
      this.value = value;

      // validate
      this.validate();

      // update form state
      this.field.updateState(
        this.field.valueField.name,
        this.value,
        this.isValid,
        this.isEnabled,
        this.isVisible
      );
    },
    /**
     * Shows current field
     */
    show() {
      this.isVisible = true;
      this.required = this.field.model.required;
    },
    /**
     * Hides current field
     */
    hide() {
      this.isVisible = false;
      this.required = false;
    },
    /**
     * Enables current field
     */
    enable() {
      this.isEnabled = true;
      this.required = this.field.model.required;
    },
    /**
     * Disables current field
     */
    disable() {
      this.isEnabled = false;
      this.required = false;
    },
  },
};
</script>
