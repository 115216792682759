<template>
  <animation-slide>
    <form-select
      v-show="isVisible"
      :id="field.valueField.id"
      v-model:value="value"
      :title="title"
      :class="field.model.cssClass"
      :name="field.valueField.name"
      :required="required"
      :disabled="!isEnabled"
      @input="onInput($event.target.value)"
      @focus="field.tracker.onFocusField(field, value)"
      @change="onChange"
      @blur="field.tracker.onBlurField(field, value, errors.value)"
      :options="options"
      :error-message="errors.value.join(',')"
      icon="error"
    />
  </animation-slide>
</template>

<script>
import FormSelect from '@pon/pu-molecule-form-select';
import BaseField from './BaseField.vue';
import addEventToDigitalData from '../../helpers/DigitalData';

export default {
  name: 'DropdownList',
  extends: BaseField,
  components: {
    FormSelect,
  },
  computed: {
    options() {
      const options = this.field.model.items.map((item) => {
        return {
          id: item.itemId,
          selected: item.selected,
          text: item.text,
          value: item.value,
        };
      });

      if (this.field.model.showEmptyItem) {
        const emptyOption = {
          id: `${this.field.valueField.id}-empty`,
          text: '',
          value: '',
        };
        return [emptyOption, ...options];
      }
      return options;
    },
  },
  methods: {
    onChange() {
      this.$emit('change');
      if (this.errors.value[0]) {
        addEventToDigitalData('formError', {
          eventAction: this.metadata.name,
          eventCategory: 'forms',
          eventLabel: 'form error',
          formName: this.metadata.name,
          formFieldError: this.field.model.name,
          formErrorMessage: this.errors.value[0],
        });
      }
    },
  },
  beforeMount() {
    const selectedOption = this.options.find((option) => option.selected);
    if (selectedOption) {
      this.value = selectedOption.value;
    } else {
      this.value = this.options[0].value;
    }
  },
};
</script>
