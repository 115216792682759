<template>
  <label
    :for="field.valueField.id"
    :class="[field.model.labelCssClass, cssClass, { 'is-invalid': !isValid }]"
    class="form-label"
  >
    <slot />
    <span v-html="field.model.title" />
    <span class="required-asterisk" v-if="field.model.required">*</span>
  </label>
</template>

<script>
export default {
  props: {
    field: {
      type: Object,
      required: true,
    },
    cssClass: {
      type: String,
      default: '',
    },
    isValid: {
      type: Boolean,
      default: true,
    },
  },
};
</script>
