/**
 * Vuelidate Sitecore Date validator
 * @author Rocco Janse, roccojanse@outlook.com
 */
import { helpers } from '@vuelidate/validators';

export default (min, max) => {
  return helpers.withParams({ type: 'date', min, max }, (value) => {
    return (
      !helpers.req(value) ||
      (new Date(value).getTime() >= new Date(min).getTime() &&
        new Date(value).getTime() <= new Date(max).getTime())
    );
  });
};
