<template>
  <div class="form-group" v-show="isVisible">
    <form-label :for="field.valueField.id" :field="field" :is-valid="isValid" />
    <div v-for="(item, index) in field.model.items" :key="item.itemId" class="form-check">
      <input
        :id="field.valueField.id + index"
        :value="item.value"
        type="checkbox"
        class="form-check-input"
        :class="[field.model.cssClass, { 'is-invalid': !isValid }]"
        :name="field.valueField.name"
        :checked="value.some((v) => v === item.value)"
        :disabled="!isEnabled"
        @change="onInput($event.target)"
        @focus="field.tracker.onFocusField(field, value)"
        @blur="field.tracker.onBlurField(field, value, errors.value)"
      />
      <label
        :for="field.valueField.id + index"
        class="form-check-label"
        :class="{ 'is-invalid': !isValid }"
      >
        {{ item.text }}
      </label>
    </div>
    <error-messages v-if="errors.value.length > 0" :errors="errors.value" />
  </div>
</template>

<script>
import BaseField from './BaseField.vue';
import FormLabel from './FormLabel.vue';

export default {
  name: 'CheckboxList',
  components: {
    FormLabel,
  },
  extends: BaseField,
  data() {
    return {
      value: this.field.value,
      isValid: true,
      required: this.field.model.required,
    };
  },
  methods: {
    /**
     * Updates current field state
     * @param {any} value Current field value
     * @returns void
     */
    onInput(node) {
      // we can have multiple selected values. So we need to push ALL the selected
      // values back up to the root form. This is done using an array, which the form
      // serializer knows how to expand into multiple values on post
      if (node.checked) {
        this.value.push(node.value);
      } else {
        this.value = this.value.filter((v) => v !== node.value);
      }

      // validate
      this.validate();

      // update form state
      this.field.updateState(this.field.valueField.name, this.value, this.isValid);
    },
  },
};
</script>
