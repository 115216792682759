<template>
  <div class="form-group" v-show="isVisible">
    <form-label :for="field.valueField.id" :field="field" :is-valid="isValid" />
    <div v-for="(item, index) in field.model.items" :key="item.itemId" class="form-check">
      <input
        :id="field.valueField.id + index"
        :value="item.value"
        type="radio"
        class="form-check-input"
        :class="[field.model.cssClass, { 'is-invalid': !isValid }]"
        :name="field.valueField.name"
        :checked="value[0] === item.value"
        :disabled="!isEnabled"
        @input="onInput($event.target.value)"
        @focus="field.tracker.onFocusField(field, value)"
        @blur="field.tracker.onBlurField(field, value, errors.value)"
      />
      <label
        :for="field.valueField.id + index"
        class="form-check-label"
        :class="{ 'is-invalid': !isValid }"
      >
        {{ item.text }}
      </label>
    </div>
    <error-messages v-if="errors.value.length > 0" :errors="errors.value" />
  </div>
</template>

<script>
import BaseField from './BaseField.vue';
import FormLabel from './FormLabel.vue';

export default {
  name: 'RadioButtonList',
  components: {
    FormLabel,
  },
  extends: BaseField,
  methods: {
    /**
     * Updates current field state
     * @param {any} value Current field value
     * @returns void
     */
    onInput(value) {
      if (value) {
        this.value = [value];
      } else {
        this.value = [];
      }

      // validate
      this.validate();

      // update form state
      this.field.updateState(this.field.valueField.name, this.value, this.isValid);
    },
  },
};
</script>
