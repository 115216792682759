<template>
  <animation-slide>
    <div v-show="isVisible">
      <dealer-select
        :name="field.name"
        :title="title"
        :required="required"
        :id="field.valueField.id"
        :placeholder="field.model.placeholderText"
        :disabled="!isEnabled"
        :loading="loading"
        :establishments="establishments"
        :establishment-selected-id="establishmentSelectedId"
        :error="apiError"
        @select-establishment="selectEstablishment"
        @postal-code-change="handlePostalCodeChange"
      />
      <error-message :text="errors.value.join(',')" icon="error" />
    </div>
  </animation-slide>
</template>

<script>
import BaseField from './BaseField.vue';
import DealerSelect from '../../Library/DealerSelect/DealerSelect.vue';
import ErrorMessage from '@pon/pu-atom-error-message';
import urlParams from '@/plugins/url-params';
import addEventToDigitalData from '../../helpers/DigitalData';
import { fetchEstablishmentsById, fetchEstablishmentsByPostalcode } from '@/api/dealers';

export default {
  name: 'JSSDealerSelect',
  components: {
    DealerSelect,
    ErrorMessage
  },
  extends: BaseField,
  data() {
    return {
      establishments: [],
      establishmentSelectedId: null,
      apiError: null,
      loading: false,
    };
  },
  created() {
    this.$eventBus.on('update-postal-code', (postalCode) => {
      if (!this.establishments.length) {
        fetchEstablishmentsByPostalcode(postalCode).then(({ establishments, error }) => {
          this.establishments = establishments;
          this.apiError = this.getErrorMessage(error);

          const closestEstablishmentWithGeodata = establishments.find(
            (es) => es?.location.latitude && es?.location.longitude
          );
          if (closestEstablishmentWithGeodata) {
            this.selectEstablishment(closestEstablishmentWithGeodata.id);
          }
        });
      }
    });

    this.preselectDealerFromUrl();
  },
  provide() {
    return {
      translations: {
        showLessDealers: this.$t('/dealer select/dealer results/show less dealers'),
        showMoreDealers: this.$t('/dealer select/dealer results/show more dealers'),
        selectThisDealer: this.$t('/dealer select/dealer results/select this dealer'),
        selectedDealer: this.$t('/dealer select/dealer results/selected dealer'),
        closed: this.$t('/dealer select/dealer results/closed'),
        viewOpeningHours: this.$t('/dealer select/dealer results/view opening hours'),
        km: this.$t('/dealer select/dealer results/distance units of measurement'),
        search: this.$t('/dealer select/location search/search'),
        yourLocation: this.$t('/dealer select/location search/your location'),
        errorUnknowLocation: this.$t('/dealer select/location search/errors/unknown location'),
        errorGeolocationTurnedOf: this.$t(
          '/dealer select/location search/errors/geolocation turned off'
        ),
        showMap: this.$t('/dealer select/dealer map/show map'),
      },
    };
  },
  methods: {
    selectEstablishment(establishmentId) {
      // find the establishment for given id
      const selectedEstablishment = establishmentId
        ? this.establishments.find((es) => es.id === establishmentId)
        : null;

      // if establishment not found, or given id is already selected, remove the selection
      if (!selectedEstablishment || establishmentId === this.establishmentSelectedId) {
        this.establishmentSelectedId = null;
        this.value = '';
      } else {
        // otherwise, set selection to given id
        this.establishmentSelectedId = selectedEstablishment.id;
        this.value = JSON.stringify(selectedEstablishment);
        addEventToDigitalData('addMyDealer', {
          selectedEstablishment,
        });
      }

      // trigger change on field value
      this.onInput(this.value);
      this.$emit('change');
    },
    preselectDealerFromUrl() {
      const preselectedEstablishmentId = urlParams.get('DealerPreferenceSalesDealer');
      if (preselectedEstablishmentId) {
        this.loading = true;

        fetchEstablishmentsById(preselectedEstablishmentId).then(({ establishments, error }) => {
          this.establishments = establishments;
          this.establishmentSelectedId = preselectedEstablishmentId;
          this.apiError = this.getErrorMessage(error);
          this.loading = false;
          this.value = JSON.stringify(establishments[0]);
          if (establishments.length > 0) {
            addEventToDigitalData('addMyDealer', {
              selectedEstablishment: establishments[0],
            });
          }
          this.onInput(this.value);
          this.$emit('change');
        });
      }
    },
    handlePostalCodeChange(postalCode) {
      if (!postalCode) {
        this.establishments = [];
        this.establishmentSelectedId = null;
        this.apiError = '';
        this.loading = false;
      } else {
        this.loading = true;

        fetchEstablishmentsByPostalcode(postalCode).then(({ establishments, error }) => {
          this.establishments = establishments;
          this.establishmentSelectedId = null;
          this.apiError = this.getErrorMessage(error);
          this.loading = false;
        });
      }
    },
    getErrorMessage(error) {
      let errorMessage = '';
      if (error) {
        errorMessage = this.$t('/dealer select/location search/errors/dealer search failure');
      }
      return errorMessage;
    },
  },
};
</script>
