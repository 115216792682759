<template>
  <animation-slide>
    <div v-show="isVisible">
      <div :class="[$style.address, field.model.cssClass]">
        <form-input
          v-model:value="value.zipcode"
          :class="$style.zipcode"
          :id="`${field.valueField.id}-zipcode`"
          minlength="6"
          maxlength="7"
          :placeholder="field.model.zipcodePlaceholderText"
          :name="`${field.model.name}-zipcode`"
          :title="field.model.zipcodeLabel"
          :required="required"
          :disabled="!isEnabled"
          @input="onInputAddressValue('zipcode')"
          @change="changeAddressField('zipcode')"
          @blur="onBlurHandler(field, value, errors.value)"
          @focus="field.tracker.onFocusField(field, value)"
          :error-message="error.zipcode"
          icon="error"
        />

        <form-input
          type="text"
          v-model:value="value.houseNumber"
          :id="`${field.valueField.id}-house-number`"
          maxlength="10"
          :placeholder="field.model.housenumberPlaceholderText"
          :name="`${field.model.name}-house-number`"
          :title="field.model.housenumberLabel"
          :required="required"
          :disabled="!isEnabled"
          @input="onInputAddressValue('houseNumber')"
          @change="changeAddressField('houseNumber')"
          @focus="field.tracker.onFocusField(field, value)"
          @blur="onBlurHandler(field, value, errors.value)"
          :error-message="error.houseNumber"
          icon="error"
        />
        <form-input
          v-model:value="value.houseNumberSuffix"
          :id="`${field.valueField.id}-house-number-suffix`"
          maxlength="5"
          :placeholder="field.model.housenumberSuffixPlaceholderText"
          :name="`${field.model.name}-house-number-suffix`"
          :title="field.model.housenumberSuffixLabel"
          :disabled="!isEnabled"
          @input="onInputAddressValue('houseNumberSuffix')"
          @change="changeAddressField('houseNumberSuffix')"
          @focus="field.tracker.onFocusField(field, value)"
          @blur="onBlurHandler(field, value, errors.value)"
          :error-message="error.houseNumberSuffix"
          icon="error"
        />
      </div>
      <app-loader v-if="loading" :class="$style.loading" />
      <div :class="$style['address-line']">
        {{ addressLine }}
      </div>
      <error-message
        v-show="errors.value.length"
        id="error-message-address-line"
        icon="error"
        :text="errors.value.join(',')"
      />
    </div>
  </animation-slide>
</template>

<script>
import axios from '../../plugins/axios-apim';
import { required, numeric, helpers } from '@vuelidate/validators';
import BaseField from './BaseField';
import FormInput from '@pon/pu-molecule-form-input';
import AppLoader from '@pon/pu-atom-app-loader';
import ErrorMessage from '@pon/pu-atom-error-message';

export default {
  extends: BaseField,
  components: {
    FormInput,
    AppLoader,
    ErrorMessage,
  },
  validations() {
    const isZipCode = helpers.regex(/^[1-9]\d{3}\s?[a-zA-Z]{2}$/);
    const validations = {
      value: {
        zipcode: {
          isZipCode,
        },
        houseNumber: {
          numeric,
        },
      },
    };
    // Only set required if whole field is required
    if (this.field.model.required) {
      validations.value.zipcode.required = required;
      validations.value.houseNumber.required = required;
    }
    return validations;
  },
  data() {
    return {
      value: {
        zipcode: '',
        houseNumber: '',
        houseNumberSuffix: '',
        city: '',
        street: '',
      },
      loading: false,
      error: {
        zipcode: null,
        houseNumber: null,
        houseNumberSuffix: null,
      },
      touched: {
        zipcode: false,
        houseNumber: false,
        houseNumberSuffix: false,
      },
    };
  },
  computed: {
    addressLine() {
      const houseNumberSuffix = this.value.houseNumberSuffix
        ? `-${this.value.houseNumberSuffix}`
        : '';
      if (this.value.city && this.value.street) {
        return `${this.value.street}
        ${this.value.houseNumber}${houseNumberSuffix},
        ${this.value.city.toLowerCase()}`;
      }
      return null;
    },
  },
  methods: {
    async fetchCityAndStreet() {
      this.loading = true;
      const data = {
        address: {
          zipcode: this.value.zipcode,
          houseNumber: this.value.houseNumber,
          houseNumberSuffix: this.value.houseNumberSuffix,
        },
      };
      try {
        const url = `${process.env.VUE_APP_APIM_HOST}${process.env.VUE_APP_APIM_CUSTOMERS_PATH}/contact/validateaddress`;
        const response = await axios.post(url, data);
        this.setCityAndStreet(response.data);
      } catch (error) {
        this.clearCityAndStreet(this.$t('/contact details/error message/address request failure'));
      } finally {
        this.loading = false;
      }
    },
    clearCityAndStreet(error) {
      this.value.city = null;
      this.value.street = null;
      this.errors.value[0] = error;
      this.updateStateWithJson();
    },
    setCityAndStreet({ addresses }) {
      if (!addresses?.length) {
        this.clearCityAndStreet(this.$t('/contact details/error message/invalid address line'));
        return;
      }
      this.value.city = addresses[0].city;
      this.value.street = addresses[0].street;
      this.updateStateWithJson();
    },
    getZipcodeErrorMessage() {
      const validator = this.v$.value;
      if (validator.value.zipcode.$invalid) {
        if (!validator.value.zipcode.required.$invalid) {
          return this.$t('/contact details/error message/fill zipcode');
        }
        if (!validator.value.zipcode.isZipCode.$invalid) {
          return this.$t('/contact details/error message/invalid zipcode');
        }
      }
      return null;
    },
    getHousenumberErrorMessage() {
      const validator = this.v$.value;
      if (validator.value.houseNumber.$invalid) {
        if (!validator.value.houseNumber.required.$invalid)
          return this.$t('/contact details/error message/fill housenumber');
        if (!validator.value.houseNumber.numeric.$invalid)
          return this.$t('/contact details/error message/invalid housenumber');
      }
      return null;
    },
    // Overwrite the validate from the basefield
    validate() {
      let isValid = true;
      if (this.v$.value) {
        this.v$.value.value.$touch();
        isValid = !this.v$.value.value.$error;
        this.getValidationErrors(this.v$.value);
      }
      // Custom logic for checking if address was filled using api
      if (this.field.model.required && (!this.value.city || !this.value.street)) {
        if (!this.errors.value.length) {
          const error = this.field.translations.validations.required;
          const msg = error.replace('{0}', this.field.model.title);
          this.errors.value[0] = msg.replace(/ +/g,' ').trim();
        }
        isValid = false;
      }
      this.isValid = isValid;
      return this.isValid;
    },
    validateAddressFields(field) {
      switch (field) {
        case 'zipcode':
          this.error.zipcode = this.getZipcodeErrorMessage();
          break;
        case 'houseNumber':
          this.error.houseNumber = this.getHousenumberErrorMessage();
          break;
        default:
      }
    },
    changeAddressField(field) {
      this.touched[field] = true;
      this.validateAddressFields(field);
      if (!this.v$.value.value.zipcode.$invalid && !this.v$.value.value.houseNumber.$invalid) {
        this.fetchCityAndStreet();
        this.$eventBus.emit('update-postal-code', this.value.zipcode);
      } else {
        this.clearCityAndStreet(null);
      }
    },
    onInputAddressValue(field) {
      this.clearCityAndStreet(null);
      if (this.touched[field]) {
        this.validateAddressFields(field);
      }
    },
    updateStateWithJson() {
      const valueJson = JSON.stringify(this.value);
      this.field.updateState(
        this.field.valueField.name,
        valueJson,
        this.isValid,
        this.isEnabled,
        this.isVisible
      );
    },
  },
};
</script>

<style lang="scss" module>
.address {
  display: grid;
  grid-gap: 16px;
  grid-template-columns: repeat(2, 1fr);

  @include breakpoint(sm) {
    grid-template-columns: repeat(3, 1fr);
  }
  @include breakpoint(lg) {
    grid-template-columns: repeat(6, 1fr);
  }
}

.zipcode {
  grid-column: span 2;
  @include breakpoint(sm) {
    grid-column: span 1;
  }
}

.address-line {
  color: $gray;
  white-space: nowrap;
  text-transform: capitalize;
}

.loading {
  margin: 0.25em 0 0;
  font-size: $font-size-s;
}
</style>
