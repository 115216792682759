<template>
  <div class="form-group" v-show="isVisible">
    <form-label :for="field.valueField.id" :field="field" :is-valid="isValid" />
    <textarea
      :id="field.valueField.id"
      v-model="value"
      class="form-control"
      :class="[field.model.cssClass, { 'is-invalid': !isValid }]"
      :name="field.valueField.name"
      :rows="field.model.rows"
      :minLength="field.model.minLength"
      :maxLength="field.model.maxLength"
      :placeholder="field.model.placeholderText"
      :required="required"
      :disabled="!isEnabled"
      @input="onInput($event.target.value)"
      @focus="field.tracker.onFocusField(field, value)"
      @blur="field.tracker.onBlurField(field, value, errors.value)"
    />
    <error-messages v-if="errors.value.length > 0" :errors="errors.value" />
  </div>
</template>

<script>
import BaseField from './BaseField.vue';
import FormLabel from './FormLabel.vue';

export default {
  name: 'MultiLineText',
  components: {
    FormLabel,
  },
  extends: BaseField,
};
</script>
