// map Sitecore fieldTypeItemIds to something human-readable
// sitecore/system/Settings/Forms/Field Types/[several folders]
export const Button = '{7CE25CAB-EF3A-4F73-AB13-D33BDC1E4EE2}';
export const CheckboxField = '{4DA85E8A-3B48-4BC6-9565-8C1F5F36DD1B}';
export const CheckboxList = '{D86A361A-D4FF-46B2-9E97-A37FC5B1FE1A}';
export const DateField = '{38137D30-7B2A-47D5-BBD8-133252C01B28}';
export const DropdownList = '{E0CFADEE-1AC0-471D-A820-2E70D1547B4B}';
export const EmailField = '{04C39CAC-8976-4910-BE0D-879ED3368429}';
export const FormSection = '{447AA745-6D29-4B65-A5A3-8173AA8AF548}';
export const ListBox = '{222A2121-D370-4C6F-80A3-03C930B718BF}';
export const MultipleLineText = '{A296A1C1-0DA0-4493-A92E-B8191F43AEC6}';
export const NumberField = '{5B153FC0-FC3F-474F-8CB8-233FB1BEF292}';
export const PasswordConfirmField = '{6293530F-36A1-4CA6-A2E6-C59C9343F096}';
export const PasswordField = '{668A1C37-9D6B-483B-B7C1-340C92D04BA4}';
export const RadioButtonList = '{EDBD38A8-1AE9-42EC-8CCD-F5B0E2998B4F}';
export const RichText = '{983BFA5F-C6B6-4AEE-A3BB-46B95D52E7DF}';
export const SingleLineText = '{4EE89EA7-CEFE-4C8E-8532-467EF64591FC}';
export const TelephoneField = '{DF74F55B-47E6-4D1C-92F8-B0D46A7B2704}';
export const TextField = '{983BFA5F-C6B6-4AEE-A3BB-46B95D52E7DF}';
export const HiddenField = '{B554A364-3493-4B7A-9582-D6523789B2FB}';
export const AddressField = '{46B5530F-D622-421F-BDCF-1AAC03609D51}';
export const DealerSelect = '{6FBBFEBB-18A7-45A7-957D-D53E23EFD76A}';
export const ModelSelect = '{A01811AD-F3C7-4BFD-AE65-607B0252C208}';
export const Captcha = '{436529BD-E2D4-46C9-A5CA-436FE22C543D}';
