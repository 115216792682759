import GoogleMapsApiLoader from 'google-maps-api-loader';

let promise;

export async function googleMapsApi(apiKey) {
  if (!promise) {
    promise = GoogleMapsApiLoader({
      apiKey,
      libraries: ['places'],
    });
  } else if (!apiKey) {
    console.warn('googleMapsApi: No apiKey given!');
  }

  return promise;
}

export default googleMapsApi;
