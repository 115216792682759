<template>
  <dl :class="$style.days">
    <template v-for="day in sortedOpeningDays" :key="day.day">
      <dt :class="[{ [$style.current]: day.isCurrentDay }, $style.day]">
        {{ day.day }}
      </dt>
      <dd :class="{ [$style.current]: day.isCurrentDay }">
        {{ getOpeningHoursText(day.hours) }}
      </dd>
    </template>
  </dl>
</template>

<script>
import { format, parse, isSameDay } from 'date-fns';
import { nl } from 'date-fns/locale';

export default {
  props: {
    openingTimes: {
      type: Object,
      required: true,
    },
  },
  inject: {
    translations: {
      default: {
        closed: 'closed',
      },
    },
  },
  computed: {
    sortedOpeningDays() {
      return Object.keys(this.openingTimes).map((key) => {
        return {
          day: this.translateDate(key),
          isCurrentDay: this.isCurrentDay(key),
          hours: this.openingTimes[key],
        };
      });
    },
  },
  methods: {
    convertDayToDate(value) {
      return parse(value, 'cccc', new Date());
    },
    translateDate(value) {
      const date = this.convertDayToDate(value);
      return format(date, 'cccccc', { locale: nl });
    },
    isCurrentDay(value) {
      return isSameDay(this.convertDayToDate(value), new Date());
    },
    getOpeningHoursText(day) {
      if (!day.openingTime || !day.closingTime) {
        return this.translations.closed;
      }

      return `${day.openingTime} - ${day.closingTime}`;
    },
  },
};
</script>

<style module lang="scss">
.days {
  display: grid;
  grid-template-columns: 3em auto;
}

.day {
  text-transform: capitalize;
}

.current {
  font-weight: $font-weight-bold;
}
</style>
