<template>
  <div>
    <error-message :text="errors.value.join(',')" icon="error" />
  </div>
</template>
<script>
import BaseField from './BaseField.vue';
import ErrorMessage from '@pon/pu-atom-error-message';

export default {
  name: 'Captcha',
  extends: BaseField,
  components: {
    ErrorMessage,
  },
};
</script>
